import React, { Component } from "react";
import Slider from "react-rangeslider";
import ACTIONS from "../modules/actions/player";
import { connect } from "react-redux";
import "react-rangeslider/lib/index.css";
import "../assets/css/volumecontrol.css";

var mapStateToProps = state => {
  return {
    volume: state.player.volume,
    activetype: state.player.activeSong.type
  };
};

var mapDispatchToProps = dispatch => ({
  setVolume: val => dispatch(ACTIONS.setVolume(val))
});

class VolumeControl extends Component {
  handleOnChange = value => {
    this.props.setVolume(value);
    if (this.props.activetype !== "normal" && this.props.activespot)
      this.props.activespot.setVolume(value);
    else this.props.player.volume = value;
  };

  muteUpVolume() {
    if (this.props.volume > 0) {
      if (this.props.activetype !== "normal")
        this.props.activespot.setVolume(0);
      else this.props.player.volume = 0;
      this.props.setVolume(0);
    } else {
      if (this.props.activetype !== "normal")
        this.props.activespot.setVolume(0.4);
      else this.props.player.volume = 0.4;
      this.props.setVolume(0.4);
    }
  }
  mouseEnter() {
    var obj = {
      type: "volumeToggleOn"
    };
    var url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;
    // Change this domain to the parent window domain
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2];
    window.parent.postMessage(obj, result);
  }

  mouseLeave() {
    var obj = {
      type: "volumeToggleOf"
    };
    var url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;
    // Change this domain to the parent window domain
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2];
    window.parent.postMessage(obj, result);
  }
  render() {
    let { volume } = this.props;
    return (
      <div
        className="volume"
        onMouseEnter={this.mouseEnter.bind(this)}
        onMouseLeave={this.mouseLeave.bind(this)}
        onClick={() => this.muteUpVolume()}
      >
        <div className="icon">
          {volume == 0 ? (
            <div>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 22 22"
              >
                <path
                  d="M11.7,19c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3l-4.1-4.1H1.1c-0.3,0-0.6-0.1-0.8-0.3
        C0.1,15.2,0,14.9,0,14.6V8c0-0.3,0.1-0.6,0.3-0.8C0.5,7,0.8,6.9,1.1,6.9h4.7l4.1-4.1c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
        c0.2,0.2,0.3,0.5,0.3,0.8V19z"
                />
                <g>
                  <path
                    d="M17.2,11.2l1.7,1.7c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.3-0.1,0.4L18.5,14c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1
          l-1.7-1.7L14.4,14c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1l-0.4-0.4c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.3,0.1-0.4l1.7-1.7
          l-1.7-1.7c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.3,0.1-0.4l0.4-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1l1.7,1.7l1.7-1.7
          c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1l0.4,0.4C18.9,8.9,19,9.1,19,9.2c0,0.1,0,0.3-0.1,0.4L17.2,11.2z"
                  />
                </g>
              </svg>
            </div>
          ) : (
            <div>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 22 22"
              >
                <g>
                  <path
                    d="M11.7,19c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3l-4.1-4.1H1.1c-0.3,0-0.6-0.1-0.8-0.3
                C0.1,15.2,0,14.9,0,14.6V8c0-0.3,0.1-0.6,0.3-0.8C0.5,7,0.8,6.9,1.1,6.9h4.7l4.1-4.1c0.2-0.2,0.5-0.3,0.8-0.3
                c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8V19z M17.1,9.2c-0.4-0.7-0.9-1.2-1.6-1.6c-0.3-0.2-0.7-0.3-1.1-0.2
                C14,7.5,13.7,7.7,13.5,8c-0.2,0.4-0.3,0.7-0.2,1.1c0.1,0.4,0.3,0.7,0.7,0.9c0.5,0.3,0.7,0.7,0.7,1.2c0,0.5-0.2,0.9-0.6,1.2
                c-0.3,0.2-0.5,0.6-0.6,1s0,0.8,0.3,1.1c0.2,0.3,0.5,0.5,0.9,0.6c0.4,0.1,0.8,0,1.1-0.2c0.6-0.4,1-1,1.4-1.6c0.3-0.6,0.5-1.3,0.5-2
                C17.6,10.6,17.4,9.8,17.1,9.2z M20.9,7c-0.8-1.3-1.8-2.4-3.1-3.2c-0.3-0.2-0.7-0.3-1.1-0.2c-0.4,0.1-0.7,0.3-0.9,0.7
                c-0.2,0.4-0.3,0.7-0.2,1.1c0.1,0.4,0.3,0.7,0.7,0.9c0.9,0.5,1.5,1.2,2,2.1c0.5,0.9,0.8,1.8,0.8,2.9c0,0.9-0.2,1.8-0.7,2.7
                c-0.4,0.9-1.1,1.6-1.9,2.1c-0.3,0.2-0.5,0.6-0.6,1c-0.1,0.4,0,0.8,0.3,1.1c0.3,0.4,0.7,0.6,1.2,0.6c0.3,0,0.6-0.1,0.8-0.3
                c1.2-0.8,2.1-1.9,2.8-3.2c0.7-1.3,1-2.6,1-4.1C22,9.8,21.6,8.3,20.9,7z"
                  />
                </g>
              </svg>
            </div>
          )}
        </div>
        <div className="slider-container" onClick={e => e.stopPropagation()}>
          <div className="slide">
            <Slider
              min={0}
              max={1}
              step={0.1}
              tooltip={false}
              value={volume}
              orientation="vertical"
              onChange={this.handleOnChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VolumeControl);
