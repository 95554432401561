import React, { Component } from "react";
import ACTIONS from "../modules/actions/player";
import { connect } from "react-redux";

var mapStateToProps = (state) => {
  return {
    activesong: {
      title: state.player.activeSong.title,
      desc: state.player.activeSong.desc,
      image: state.player.activeSong.image,
      link: state.player.activeSong.link,
      id: state.player.activeSong.id,
      type: state.player.activeSong.type,
    },
    audiolist: state.player.audio,
    volume: state.player.volume,
    recentPlayed: state.player.recentPlayed,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setActiveSong: (item) => dispatch(ACTIONS.setActiveSong(item)),
  toggleSong: (cond) => dispatch(ACTIONS.toggleSong(cond)),
  addQueue: (items) => dispatch(ACTIONS.addQueue(items)),
  setRecentPlayed: (items) => dispatch(ACTIONS.setRecentPlayed(items)),
  setDuration: (item) => dispatch(ACTIONS.setDuration(item)),
  setCompleted: (item) => dispatch(ACTIONS.setCompleted(item)),
});

class PreviousButton extends Component {
  previousAudio() {
    if (typeof this.props.recentPlayed[0] === "undefined") {
      console.log("undefined track");
    } else {
      let foundItem = this.props.audiolist.find(
        (item) => item.id == this.props.activesong.id
      );
      this.props.saveAudioHistory(foundItem);

      if (this.props.activesong.type == "podcast") {
        this.props.setDuration({
          trackId: this.props.activesong.id,
          duration: this.props.aud.currentTime,
        });

        const prevTrack = this.props.activesong;
        this.props.setCompleted(prevTrack);
      }

      var newaud = this.props.recentPlayed[0];
      newaud["recent"] = true;

      this.props.setRecentPlayed(this.props.recentPlayed.slice(1));
      this.props.addQueue(newaud);
      this.props.toggleSong(true);
      let tempObj = newaud;
      tempObj.previousClicked = true;

      this.props.setActiveSong(tempObj);
      this.props.spotifyTimerToggle(false);

      if (this.props.activesong.type === "spotify") {
        if (this.props.activespot) {
          this.props.activespot.seek(0);
          this.props.activespot.pause();
        }
      } else {
        this.props.aud.pause();
        this.props.aud.currentTime = 0;
      }

      if (newaud.type === "spotify") {
        this.props.activespot.loadTrack(newaud.link);
      } else {
        // this.props.aud.pause();
        // this.props.aud.currentTime = 0;
        let load = this.props.createAudio(0.4);
        load.src = newaud.link;
        load.load();
        load.play();

        // this.props.setActiveAudio(load, newaud.link);
      }
    }
  }
  render() {
    return (
      <div className="previous" onClick={this.previousAudio.bind(this)}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 10.2 11.7"
        >
          <polygon points="10.2,0 1.4,5.3 1.4,0 0,0 0,11.7 1.4,11.7 1.4,6.2 10.2,11.7" />
        </svg>{" "}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousButton);
