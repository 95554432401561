import { createStore, applyMiddleware } from 'redux';

// Logger with default options
// import logger from 'redux-logger';

import reducer from './reducers/index';

export default function configureStore(initialState) {
  // Define the middleware array
  const middlewares = [];

  // uncomment below line if want to enable redux logs in development mode
  // if (process.env.NODE_ENV !== 'production') {
  //   middlewares.push(logger);
  // }

  // Create the Redux store with the appropriate middlewares
  const store = createStore(reducer, initialState, applyMiddleware(...middlewares));

  return store;
}
