import React, { Component } from "react";

class AlbumArt extends Component {
  state = { loading: true };

  handleImageLoaded() {
    this.setState({
      loading: false,
    });
  }
  render() {
    var { title, image } = this.props.data;
    let artist = false;
    let newtitle = "";
    if (title && title.includes(":")) {
      artist = title.split(":");
      newtitle = artist[0];
      artist = artist[1];
    } else {
      newtitle = title;
    }
    return (
      <div>
        {this.props.data ? (
          <div>
            <div
              className={`album-art loading ${
                this.state.loading ? "loading-enable" : ""
              }`}
            >
              <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw loading-icon" />{" "}
              <img className="hover" src={image} alt="" />
              <img
                alt=""
                onLoad={this.handleImageLoaded.bind(this)}
                src={image}
              />{" "}
            </div>
            <div className="metadata ">
              {newtitle ? (
                <div
                  className={`track-name ${
                    newtitle.length > 25 ? "text-scrolling" : ""
                  }`}
                >
                  <span>{newtitle}</span>
                </div>
              ) : (
                ""
              )}
              {artist ? (
                <div
                  className={`track-artist ${
                    artist.length > 50 ? "text-scrolling" : ""
                  }`}
                >
                  {artist}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default AlbumArt;
