import moment from "moment";

export function base64ToArrayBuffer(base64) {
  var mimeindex = base64.indexOf("base64,");
  base64 = base64.slice(mimeindex + 7);
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function // Long news articles duration
loadDuration(audioContent) {
  window.AudioContext = window.AudioContext || window.webkitAudioContext;
  var context = new AudioContext();
  return new Promise((resolve, reject) => {
    context.decodeAudioData(audioContent, function (buffer) {
      var seconds = buffer.duration;
      var duration = moment.duration(seconds, "seconds");

      var time = "";
      var hours = duration.hours();
      if (hours > 0) {
        time = hours + ":";
      }

      time = time + duration.minutes() + ":" + duration.seconds();
      resolve(time);
    });
  });
}

export function fetchTrack(url) {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      var reader = new FileReader();

      reader.readAsDataURL(blob);

      return new Promise((resolve, reject) => {
        reader.onload = async function () {
          var arraybuf = await new Response(blob)
            .arrayBuffer()
            .then((res) => res);
          // var arraybuf = await blob.arrayBuffer().then(res => res)
          resolve([arraybuf, this.result]);
        }; // <--- `this.result` contains a base64 data URI
      });
    });
}

export async function fadeVolIn(player, newPercent) {
  var timer = setInterval(async () => {
    if (newPercent <= 1) {
      await player.setVolume(newPercent);
      newPercent += newPercent + 0.05;
    } else {
      clearInterval(timer);
    }
  }, 1000);
}

export async function htmlFadeVolIn(player, oldPercent, newPercent, time) {
  var percentage = oldPercent;
  var timer = setInterval(async () => {
    if (percentage <= newPercent) {
      player.volume = oldPercent;
      newPercent = oldPercent + 0.05;
    } else {
      clearInterval(timer);
    }
  }, time);
}

export async function htmlFadeVolOut(player, newPercent, time) {
  var timer = setInterval(async () => {
    if (newPercent >= 0) {
      player.volume = newPercent;
      newPercent = newPercent - 0.05;
    } else {
      clearInterval(timer);
      player.volume = 0;
    }
  }, time);
}
