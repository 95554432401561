import ACTIONS from '../actions/player'
import _ from 'lodash'
import axios from 'axios'
import { SUBTYPE_CONSTANTS } from '../../constants/subtypeConstants'
const arrayMove = require('array-move')

var defaultState = {
  audio: [],
  activeSong: {},
  playing: false,
  visible: false,
  playlist: false,
  search: false,
  volume: 1,
  recentPlayed: [],
  lastPlayed: { id: false },
  audioHistory: [],
  stationMode: false,
  sessionMode: false,
  shuffle: false,
}
// test
var playerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.setActiveImage: {
      let newState = _.cloneDeep(state)
      newState.activeSong = {
        ...newState.activeSong,
        image: action.payload.image,
        link: action.payload.link,
        storyId: action.payload._id,
      }
      console.log('active song is', newState.activeSong)
      return newState
    }

    case ACTIONS.Types.setShuffleMode: {
      let newState = _.cloneDeep(state)
      newState.shuffle = action.payload
      return newState
    }

    case ACTIONS.Types.setSessionMode: {
      let newState = _.cloneDeep(state)
      newState.sessionMode = action.payload
      return newState
    }

    case ACTIONS.Types.setStationMode: {
      let newState = _.cloneDeep(state)
      newState.stationMode = action.payload
      return newState
    }

    case ACTIONS.Types.toggleSong: {
      let newState = _.cloneDeep(state)
      newState.playing = action.payload
      return newState
    }

    case ACTIONS.Types.SET_AUDIO_HISTORY: {
      let newState = _.cloneDeep(state)
      newState.audioHistory = action.payload
      return newState
    }

    case ACTIONS.Types.TOGGLE_SHUFFLE: {
      let newState = _.cloneDeep(state)
      newState.shuffle = action.payload
      return newState
    }

    case ACTIONS.Types.TOGGLE_VISIBILITY: {
      let newState = _.cloneDeep(state)
      newState.visible = action.payload
      return newState
    }

    case ACTIONS.Types.TOGGLE_PLAYLIST: {
      let newState = _.cloneDeep(state)
      newState.playlist = action.payload
      return newState
    }

    case ACTIONS.Types.TOGGLE_SEARCH: {
      let newState = _.cloneDeep(state)
      newState.search = action.payload
      return newState
    }

    case ACTIONS.Types.SET_VOLUME: {
      let newState = _.cloneDeep(state)
      newState.volume = action.payload
      return newState
    }

    case ACTIONS.Types.SET_COMPLETED: {
      let newState = _.cloneDeep(state)
      newState.audio = newState.audio.map((item) => {
        let tempobj = item
        if (item.id == action.payload.id) tempobj.completed = true
        return tempobj
      })
      return newState
    }
    case ACTIONS.Types.SET_QUEUE: {
      let newState = _.cloneDeep(state)
      newState.audio = action.payload
      return newState
    }
    case ACTIONS.Types.SET_DURATION: {
      let newState = _.cloneDeep(state)
      newState.audio = newState.audio.map((item) => {
        let tempobj = item
        if (item.id == action.payload.trackId)
          tempobj.duration = action.payload.duration
        return tempobj
      })
      return newState
    }
    case ACTIONS.Types.SET_RECENT_PLAYED: {
      let newState = _.cloneDeep(state)
      newState.recentPlayed = action.payload.filter(
        (item) => item !== undefined || item !== null
      )
      return newState
    }
    case ACTIONS.Types.ADD_QUEUE: {
      let newState = _.cloneDeep(state)
      newState.audio.unshift(action.payload)
      return newState
    }
    case ACTIONS.Types.ADD_SONG: {
      let newState = _.cloneDeep(state)
      if (action.payload == false) {
        newState.activeSong = false
        return newState
      } else {
        var newobj = {}
        newobj.title = action.payload.title
        newobj.desc = action.payload.desc
        newobj.image = action.payload.image
        newobj.link = action.payload.link
        newobj.id = action.payload.id
        newobj.length = action.payload.length
        newobj.type = action.payload.type
        newobj.completed = false
        newobj.duration = 0
        newobj.isPost = action.payload.isPost ? action.payload.isPost : false
        newobj.advertisementType = action.payload.advertisementType || '';
        newobj.disableAd = action.payload.disableAd || false;
        newobj.mainId = action.payload.mainId
        if (action.payload.isPost && action.payload.isPost.type == 'STORY')
          newobj.storyId = action.payload.isPost.storyLines[0]._id

        let activeSong = newState.audio.find(
          (ite) => ite.id == newState.activeSong.id
        )
        if (!action.payload.hasOwnProperty('previousClicked'))
          if (!action.payload.previousClicked)
            if (
              newState.activeSong.id &&
              newState.activeSong.id !== newobj.id
            ) {
              activeSong['updated'] = +new Date()
            }

        if (activeSong)
          if (newState.recentPlayed.find((item) => item.id == activeSong.id))
            var newPlayed = newState.recentPlayed.map((item) => {
              if (item.id === activeSong.id) {
                item.duration = activeSong.duration
                item.completed = true
              }
              return item
            })
          else {
            var newPlayed = newState.recentPlayed
            newState.recentPlayed = [
              { ...activeSong, completed: true },
              ...newPlayed,
            ]
          }

        newState.activeSong = newobj
        newState.audio = newState.audio.filter((item) =>
          item.subtype
            ? item.subtype == 'extra'
              ? item.id == newobj.id
                ? true
                : false
              : true
            : true
        )

        return newState
      }
    }

    case ACTIONS.Types.ADD_PLAYLIST: {
      let newState = _.cloneDeep(state)
      let newItem = action.payload

      if (newItem.subtype === SUBTYPE_CONSTANTS.ADVERTISEMENT) {
        newState.audio.push(newItem)
        return newState
      }

      const activeindex = newState.audio.findIndex(
        (item) => item.id === newState.activeSong.id
      )

      if (newItem.subtype == 'extra') {
        if (activeindex == 0) newState.audio.unshift(newItem)
        else newState.audio.splice(activeindex, 0, newItem)
        return newState
      }
      if (newItem.isPost && newItem.isPost.type == 'STORY')
        newItem = {
          ...newItem,
          storyId: newItem.isPost.storyLines[0]._id,
        }
      if (newItem.recent) {
        let curIndex = newState.audio.findIndex(
          (item) => item.id == newState.activeSong.id
        )

        if (curIndex >= 0) newState.audio.splice(curIndex + 1, 0, newItem)
        else newState.audio.push(newItem)
        return newState
      } else newState.audio.push(newItem)

      if (newState.shuffle) return newState

      let currentarr = newState.audio.slice(activeindex + 1)
      let newsarr = currentarr.filter((item) => item.type === 'normal')
      let musicarr = currentarr.filter((item) => item.type === 'spotify')
      let podcasts = currentarr.filter((item) => item.type === 'podcast')
      let shorts = currentarr.filter((item) => item.type === 'shorts')
      let videos = currentarr.filter((item) => item.type === 'youtube')
      let newaudioarr = []
      let i = 0
      let podcastcounter = 0
      let shortcastcounter = 0
      var newscounter = 0
      var videocounter = 0

      for (i = 0; i < 300; i += 6) {
        let newstracks = newsarr.slice(newscounter, newscounter + 2)
        let podtrack = podcasts.slice(podcastcounter, podcastcounter + 1)
        let shortTrack = shorts.slice(shortcastcounter, shortcastcounter + 2)
        let videoTrack = videos.slice(videocounter, videocounter + 1)
        let musictracks = musicarr.slice(i, i + 6)
        let temparr = [
          ...musictracks,
          ...shortTrack,
          ...videoTrack,
          ...podtrack,
          ...newstracks,
        ]
        newaudioarr.push(...temparr)
        shortcastcounter += 2
        podcastcounter += 1
        newscounter += 2
        videocounter += 1
      }
      let beforeitems = newState.audio.slice(0, activeindex + 1)
      newaudioarr.unshift(...beforeitems)
      newState.audio = newaudioarr

      return newState
    }

    case ACTIONS.Types.SHUFFLE_SPOTIFYLIST: {
      let newState = _.cloneDeep(state)

      const activeindex = newState.audio.findIndex(
        (item) => item.id === newState.activeSong.id
      )

      let currentarr = newState.audio.slice(activeindex + 1)
      currentarr = currentarr.filter((item) => item.type !== 'spotify')
      currentarr.push(...action.payload)

      let newsarr = currentarr.filter((item) => item.type === 'normal')
      let musicarr = currentarr.filter((item) => item.type === 'spotify')
      let podcasts = currentarr.filter((item) => item.type === 'podcast')
      let shorts = currentarr.filter((item) => item.type === 'shorts')
      let newaudioarr = []
      let i = 0
      let podcastcounter = 0
      let newscounter = 0
      let shortcastcounter = 0

      for (i = 0; i < 300; i += 6) {
        let newstracks = newsarr.slice(newscounter, newscounter + 2)
        let podtrack = podcasts.slice(podcastcounter, podcastcounter + 1)
        let shortTrack = shorts.slice(shortcastcounter, shortcastcounter + 2)
        let musictracks = musicarr.slice(i, i + 6)
        let temparr = [
          ...musictracks,
          ...shortTrack,
          ...podtrack,
          ...newstracks,
        ]
        newaudioarr.push(...temparr)
        podcastcounter += 1
        shortcastcounter += 2
        newscounter += 2
      }

      let beforeitems = newState.audio.slice(0, activeindex + 1)
      newaudioarr.unshift(...beforeitems)
      newState.audio = newaudioarr

      return newState
    }

    case ACTIONS.Types.REMOVE_PLAYLIST: {
      let newState = _.cloneDeep(state)
      newState.audio = action.payload
      return newState
    }
    case ACTIONS.Types.PLAY_NOW: {
      let newState = _.cloneDeep(state)
      const songid = newState.activeSong.id
      var activeindex =
        newState.activeSong && newState.activeSong.id
          ? newState.audio.findIndex((item) => item.id === songid)
          : newState.queue.length - 1
      const prevIndex = newState.audio.findIndex(
        (item) => item.id === action.payload
      )

      const prevItem = { ...newState.audio[prevIndex] }
      delete newState.audio[prevIndex]

      newState.audio.splice(activeindex + 1, 0, prevItem)
      newState.audio = newState.audio.filter((item) => item)

      return newState
    }

    case ACTIONS.Types.PLAY_NEXT: {
      let newState = _.cloneDeep(state)
      const songid = newState.activeSong.id
      var activeindex = newState.audio.findIndex((item) => item.id === songid)
      if (!newState.audio.find((item) => item.id === action.payload.id)) {
        newState.audio.splice(activeindex + 1, 0, action.payload)
      } else {
        const prevIndex = newState.audio.findIndex(
          (item) => item.id === action.payload.id
        )
        // const prevItem = { ...newState.audio[prevItem] };
        delete newState.audio[prevIndex]
        newState.audio.splice(activeindex + 1, 0, action.payload)
        newState.audio = newState.audio.filter((item) => item)
      }
      return newState
    }

    case ACTIONS.Types.UPDATE_PLAYLIST: {
      var item = action.payload
      let newState = _.cloneDeep(state)
      var itemindex = newState.audio.findIndex((track) => track.id == item.id)
      if (newState.audio[itemindex]) {
        if (item.tag) newState.audio[itemindex].tag = item.tag
        if (item.title) newState.audio[itemindex].title = item.title
        if (item.image) newState.audio[itemindex].image = item.image
        if (item.type) newState.audio[itemindex].type = item.type
        if (item.type) newState.audio[itemindex].length = item.length
      }

      // To shuffle podcast in playlist
      const activeindex = newState.audio.findIndex(
        (item) => item.id === newState.activeSong.id
      )
      let currentarr = newState.audio.slice(activeindex + 1)
      let newsarr = currentarr.filter((item) => item.type === 'normal')
      let musicarr = currentarr.filter((item) => item.type === 'spotify')
      let podcasts = currentarr.filter((item) => item.type === 'podcast')
      let shorts = currentarr.filter((item) => item.type === 'shorts')

      let newaudioarr = []
      let i = 0
      let podcastcounter = 0

      let newscounter = 0
      let shortcastcounter = 0

      for (i = 0; i < 300; i += 6) {
        let newstracks = newsarr.slice(newscounter, newscounter + 2)
        let podtrack = podcasts.slice(podcastcounter, podcastcounter + 1)
        let shortTrack = shorts.slice(shortcastcounter, shortcastcounter + 2)
        let musictracks = musicarr.slice(i, i + 6)
        let temparr = [
          ...musictracks,
          ...shortTrack,
          ...podtrack,
          ...newstracks,
        ]
        newaudioarr.push(...temparr)
        podcastcounter += 1
        shortcastcounter += 2
        newscounter += 2
      }
      let beforeitems = newState.audio.slice(0, activeindex + 1)
      newaudioarr.unshift(...beforeitems)
      newState.audio = newaudioarr

      return newState
    }
    case ACTIONS.Types.UPDATE_ACTIVE: {
      var item = action.payload
      let newState = _.cloneDeep(state)

      if (newState.activeSong.id == item.id) {
        if (item.title) newState.activeSong.title = item.title
        if (item.image) newState.activeSong.image = item.image
        if (item.type) newState.activeSong.type = item.type
        if (item.length) newState.activeSong.length = item.length
      }

      return newState
    }

    case ACTIONS.Types.UPDATE_PLAYLIST_ORDER: {
      let newState = _.cloneDeep(state)
      var new_index = action.payload.new_index
      var old_index = action.payload.old_index
      newState.audio = arrayMove(newState.audio, old_index, new_index)

      return newState
    }

    case ACTIONS.Types.SET_LAST_PLAYED: {
      let newState = _.cloneDeep(state)
      newState.lastPlayed = action.payload

      return newState
    }

    case ACTIONS.Types.SCRAMBLE_PLAYLIST: {
      var item = action.payload
      let newState = _.cloneDeep(state)
      var curindex = newState.audio.findIndex(
        (track) => track.id == newState.activeSong.id
      )
      var containsArticle = false
      containsArticle = newState.audio.find((item) => item.type !== 'spotify')
      if (containsArticle) {
        var spotindex = 0
        for (var i = curindex; i <= newState.audio.length; i++) {
          if (newState.audio[i].type !== 'spotify') {
            spotindex = i
            break
          }
        }
        for (var ite = spotindex; ite <= newState.audio.length; ite++) {
          if (newState.audio[ite].type === 'spotify') {
            console.log('inside here')
            newState.audio.splice(ite, 0, action.payload)
            break
          }
        }
      } else {
        var count = 0
        var tempindex = newState.audio.length - 1
        for (var i = curindex; i < newState.audio.length; i++) {
          if (newState.audio[i].type === 'spotify') {
            count += 1
          }
          if (count === 6) {
            tempindex = i
            break
          }
        }
        if (count === 6) {
          newState.audio.splice(tempindex, 0, action.payload)
        } else {
          newState.audio.splice(tempindex, 0, action.payload)
        }
      }

      return newState
    }

    case ACTIONS.Types.RESET_PLAYER: {
      // return {
      //   ...state,
      //   activeSong: {},
      // }
      return {...defaultState}
    }
    default:
      return state
  }
}
export default playerReducer
