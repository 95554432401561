var Types = {
  toggleSong: "toggleSong",
  ADD_SONG: "ADD_SONG",
  TOGGLE_SHUFFLE: "tog_shuf",
  TOGGLE_VISIBILITY: "tog_visibility",
  TOGGLE_PLAYLIST: "tog_playlist",
  SET_VOLUME: "set_volume",
  ADD_PLAYLIST: "add_playlist",
  REMOVE_PLAYLIST: "remove_playlist",
  PLAY_NEXT: "play_next",
  UPDATE_PLAYLIST: "update_playlist",
  UPDATE_ACTIVE: "update_active",
  SCRAMBLE_PLAYLIST: "scramble_playlist",
  UPDATE_PLAYLIST_ORDER: "update_order",
  TOGGLE_SEARCH: "toggle_search",
  SHUFFLE_SPOTIFYLIST: "SHUFFLE_SPOTIFYLIST",
  SET_COMPLETED: "SET_COMPLETED",
  SET_DURATION: "SET_DURATION",
  SET_RECENT_PLAYED: "SET_RECENT_PLAYED",
  SET_QUEUE: "SET_QUEUE",
  ADD_QUEUE: "ADD_QUEUE",
  SET_LAST_PLAYED: "SET_LAST_PLAYED",
  SET_AUDIO_HISTORY: "SET_AUDIO_HISTORY",
  setStationMode: "setStationMode",
  setSessionMode: "setSessionMode",
  setShuffleMode: "setShuffleMode",
  setActiveImage: "setActiveImage",
  PLAY_NOW: "PLAY_NOW",
  RESET_PLAYER: 'RESET_PLAYER',
};
// actions
var toggleSong = (cond) => ({
  type: Types.toggleSong,
  payload: cond,
});

var setActiveSong = (item) => ({
  type: Types.ADD_SONG,
  payload: item,
});

var toggleShuffle = (cond) => ({
  type: Types.TOGGLE_SHUFFLE,
  payload: cond,
});

var toggleVisibility = (cond) => ({
  type: Types.TOGGLE_VISIBILITY,
  payload: cond,
});

var togglePlaylist = (cond) => ({
  type: Types.TOGGLE_PLAYLIST,
  payload: cond,
});

var toggleSearch = (cond) => ({
  type: Types.TOGGLE_SEARCH,
  payload: cond,
});

var setVolume = (val) => ({
  type: Types.SET_VOLUME,
  payload: val,
});

var addPlaylist = (items) => ({
  type: Types.ADD_PLAYLIST,
  payload: items,
});

var removePlaylist = (items) => ({
  type: Types.REMOVE_PLAYLIST,
  payload: items,
});

var playNext = (item) => ({
  type: Types.PLAY_NEXT,
  payload: item,
});

var playNow = (item) => ({
  type: Types.PLAY_NOW,
  payload: item,
});

var updatePlaylist = (item) => ({
  type: Types.UPDATE_PLAYLIST,
  payload: item,
});

var updateActive = (item) => ({
  type: Types.UPDATE_ACTIVE,
  payload: item,
});

var scramblePlaylist = (item) => ({
  type: Types.SCRAMBLE_PLAYLIST,
  payload: item,
});

var updateListOrder = (item) => ({
  type: Types.UPDATE_PLAYLIST_ORDER,
  payload: item,
});

var shuffleSpotifyList = (item) => ({
  type: Types.SHUFFLE_SPOTIFYLIST,
  payload: item,
});

var setCompleted = (item) => ({
  type: Types.SET_COMPLETED,
  payload: item,
});
var setDuration = (item) => ({
  type: Types.SET_DURATION,
  payload: item,
});
var setRecentPlayed = (item) => ({
  type: Types.SET_RECENT_PLAYED,
  payload: item,
});
var setQueue = (item) => ({
  type: Types.SET_QUEUE,
  payload: item,
});

var addQueue = (item) => ({
  type: Types.ADD_QUEUE,
  payload: item,
});

var setLastPlayed = (item) => ({
  type: Types.SET_LAST_PLAYED,
  payload: item,
});

var setAudioHistory = (item) => ({
  type: Types.SET_AUDIO_HISTORY,
  payload: item,
});

var setStationMode = (item) => ({
  type: Types.setStationMode,
  payload: item,
});

var setSessionMode = (item) => ({
  type: Types.setSessionMode,
  payload: item,
});

var disableShuffle = (item) => ({
  type: Types.setShuffleMode,
  payload: item,
});

var changeActiveImage = (item) => ({
  type: Types.setActiveImage,
  payload: item,
});

var resetPlayer = () => {
  return {
    type: Types.RESET_PLAYER,
  };
}

export default {
  changeActiveImage,
  disableShuffle,
  setSessionMode,
  setStationMode,
  setAudioHistory,
  setLastPlayed,
  addQueue,
  setQueue,
  setRecentPlayed,
  setDuration,
  setCompleted,
  shuffleSpotifyList,
  toggleSong,
  setActiveSong,
  toggleShuffle,
  toggleVisibility,
  togglePlaylist,
  toggleSearch,
  setVolume,
  addPlaylist,
  Types,
  removePlaylist,
  playNext,
  updatePlaylist,
  updateActive,
  scramblePlaylist,
  updateListOrder,
  playNow,
  resetPlayer
};
