import React, { Component } from 'react';
import ACTIONS from '../modules/actions/player';
import { connect } from 'react-redux';
import { fadeVolIn } from '../utils/index';
import PlayButton from './PlayButton';
import VolumeControl from './VolumeControl';
import ToggleButton from './ToggleButton';
import AlbumArt from './AlbumArt';

import PreviousButton from './PreviousButton';
import NextButton from './NextButton';
import axios from 'axios';
import Slider from '@material-ui/core/Slider';
import SearchPage from './SearchPage';
import _ from 'lodash';
import $ from 'jquery';
import { COMMUNICATION_CONSTANTS } from '../constants/communicationConstants';
import { SUBTYPE_CONSTANTS } from '../constants/subtypeConstants';

function clickDisable(e) {
  e.stopPropagation();
  return false;
}

var mapStateToProps = (state) => {
  return {
    activesong: {
      title: state.player.activeSong.title,
      desc: state.player.activeSong.desc,
      image: state.player.activeSong.image,
      link: state.player.activeSong.link,
      id: state.player.activeSong.id,
      length: state.player.activeSong.length,
      type: state.player.activeSong.type,
      isPost: state.player.activeSong.isPost,
      storyId: state.player.activeSong.storyId,
      disableAd: state.player.activeSong.disableAd,
    },
    playing: state.player.playing,
    shuffle: state.player.shuffle,
    playlist: state.player.playlist,
    visible: state.player.visible,
    volume: state.player.volume,
    search: state.player.search,
    audiolist: state.player.audio,
    recentPlayed: state.player.recentPlayed,
    lastPlayed: state.player.lastPlayed,
    audioHistory: state.player.audioHistory,
    stationMode: state.player.stationMode,
    sessionMode: state.player.sessionMode,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setActiveSong: (item) => dispatch(ACTIONS.setActiveSong(item)),
  addPlaylist: (items) => dispatch(ACTIONS.addPlaylist(items)),
  toggleSong: (cond) => dispatch(ACTIONS.toggleSong(cond)),
  toggleVisibility: (cond) => dispatch(ACTIONS.toggleVisibility(cond)),
  toggleShuffle: (cond) => dispatch(ACTIONS.toggleShuffle(cond)),
  togglePlaylist: (cond) => dispatch(ACTIONS.togglePlaylist(cond)),
  toggleSearch: (cond) => dispatch(ACTIONS.toggleSearch(cond)),
  playNext: (item) => dispatch(ACTIONS.playNext(item)),
  updatePlaylist: (item) => dispatch(ACTIONS.updatePlaylist(item)),
  updateActive: (item) => dispatch(ACTIONS.updateActive(item)),
  scramblePlaylist: (item) => dispatch(ACTIONS.scramblePlaylist(item)),
  updateListOrder: (item) => dispatch(ACTIONS.updateListOrder(item)),
  removePlaylist: (items) => dispatch(ACTIONS.removePlaylist(items)),
  shuffleSpotifyList: (items) => dispatch(ACTIONS.shuffleSpotifyList(items)),
  setCompleted: (item) => dispatch(ACTIONS.setCompleted(item)),
  setDuration: (item) => dispatch(ACTIONS.setDuration(item)),
  setRecentPlayed: (item) => dispatch(ACTIONS.setRecentPlayed(item)),
  setQueue: (item) => dispatch(ACTIONS.setQueue(item)),
  setLastPlayed: (item) => dispatch(ACTIONS.setLastPlayed(item)),
  setAudioHistory: (item) => dispatch(ACTIONS.setAudioHistory(item)),
  addQueue: (item) => dispatch(ACTIONS.addQueue(item)),
  setStationMode: (item) => dispatch(ACTIONS.setStationMode(item)),
  setSessionMode: (item) => dispatch(ACTIONS.setSessionMode(item)),
  disableShuffle: (item) => dispatch(ACTIONS.disableShuffle(item)),
  changeActiveImage: (item) => dispatch(ACTIONS.changeActiveImage(item)),
  playNow: (item) => dispatch(ACTIONS.playNow(item)),
  resetPlayer: () => dispatch(ACTIONS.resetPlayer()),
});

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totaltime: '',
      remtime: '',
      activespotplayer: null,
      djplayed: false,
      introplayed: false,
      buffer: '',
      waveposition: 0,
      // spotifyTokenRenewal: false,
      spotifyToken: false,
      spotifytries: 0,
      spotifyTimer: false,
      renewalProgress: false,
      spotifyState: null,
      theme: 'black-theme',
      spotifyLoading: false,
      announcerPlaying: false,
      teamBranding: false,
      advertisementCookieSet: false,
      introPlayingEnded: false,
      disableAds: false,
      stopTheSilenceAudio: false,
    };

    this.intermediateMusicPlaying = false;
    this.intermediateMusicContext = null;
    this.metadataLoaded = false;
    this.preloadedAudio = null;
    this.storedAudioItem = null;
    this.spotifyPlaySongLink = null;
    this.spotifyContentDuration = 0;
    this.seekInProgress = false;
    this.isSpotifyPlayerPlaying = false;
    this.spotifyStateData = null;
    this.currentSpotifyPosition = 0;
    this.introAudio = null;

    const randomNumber = parseInt(Math.random() * 10);
    const audioOneMessages = [
      'This is AudioOne, where the latest news and insightful podcasts are being curated into your personal station. Your tailored audio experience begins now.',
      'Welcome to AudioOne. Currently, we are assembling a personalized station just for you, featuring a curated selection of news and podcasts to match your interests.',
      "Here at AudioOne, we understand the value of your time and interests. That's why we're meticulously crafting a personal station, bringing together leading news and thought-provoking podcasts.",
      'Your personalized station is created right now by AudioOne, where we bring you closer to the stories that shape our world and the podcasts that expand your horizons.',
      "AudioOne is diligently working to create your personal audio station, a bespoke blend of the day's most important news and podcasts that resonate with you.",
      "Stay informed and inspired with AudioOne. We're in the process of creating a personal station tailored specifically to your preferences, featuring premium news and podcasts.",
      "AudioOne is your destination for personalized news and podcast listening. We're currently crafting a station designed to cater to your unique interests and lifestyle.",
      'Experience the difference with AudioOne. Your personal station, featuring a selection of news and podcasts, is being created to enrich your listening experience.',
      "At AudioOne, personalization meets excellence. We're assembling a station just for you, with curated news and podcasts that define quality and relevance.",
      'Great news and podcasts starts here at AudioOne. A personalized station, reflecting your tastes and interests, is now being carefully crafted by our team.',
    ];
    this.stationIntroMessage = audioOneMessages[randomNumber];
    let audio = new Audio(`https://djdev.audioone.cloud/generatevoice/${this.stationIntroMessage}`);
    this.preloadedStationAudio = audio;
  }

  componentWillReceiveProps(nextProp) {
    this.setQueue(nextProp);
  }

  setQueue(nextProp) {
    let data = _.cloneDeep(nextProp.audiolist);
    data = JSON.parse(JSON.stringify(data));

    let curplayed = _.cloneDeep(nextProp.activesong);
    curplayed.playing = nextProp.playing;
    curplayed = JSON.parse(JSON.stringify(curplayed));
    let recentPlayed = _.cloneDeep(nextProp.recentPlayed);
    recentPlayed = JSON.parse(JSON.stringify(nextProp.recentPlayed));
    window.parent.postMessage(
      {
        type: 'playQueue',
        items: data,
        currentPlayed: curplayed,
        recentPlayed: recentPlayed,
      },
      '*'
    );
  }

  async updateTrack(type, link, sid, audioContent, podcastImage = false, podcastTitle = false, podcastLength = false) {
    var self = this;
    var length = '';
    if (podcastLength) {
      length = podcastLength;
      var title = podcastTitle;
    }

    self.props.updatePlaylist({
      title: podcastTitle,
      id: sid,
      image: podcastImage,
      type: type,
      length: length,
    });

    self.props.updateActive({
      title: podcastTitle,
      id: sid,
      image: podcastImage,
      type: type,
      length: length,
    });
  }

  async componentDidMount() {
    var self = this;
    this.preloadInitialAudio();
    var initInterval = setInterval(() => {
      window.parent.postMessage(
        {
          type: 'playerLoaded',
        },
        '*'
      );
    }, 5000);

    // Window.postmessage listener from parent window
    window.addEventListener('message', async function (event) {
      var item = event.data || {};

      if (item.type === COMMUNICATION_CONSTANTS.TRIGGER_INTRO_SOUND) {
        self.isStationTriggered = item.isStationTriggered || false;
        self.state.stopTheSilenceAudio = false;
        self.playIntro.call(self);
      }

      if (item.type === COMMUNICATION_CONSTANTS.STOP_SILENCE_AUDIO) {
        self.stopSilenceAudio.call(self);
      }

      if (item.type === COMMUNICATION_CONSTANTS.RESET_PLAYER) {
        self.resetCompletePlayer.call(self);
      }

      if (item.type === 'initHeader') {
        self.updateAdvertisementState(item);
      }

      if (item.type === 'disableAds') {
        const disableAds = !!(item && item.disableAd === true);

        if (!disableAds) {
          self.setState({
            introplayed: false,
          });
        }
        self.setState({
          disableAds,
        });
      }

      // Triggered when news play button clicked
      if (item.type == 'appLoaded') {
        self.updateAdvertisementState(item);
        clearInterval(initInterval);
      }

      if (item.type == 'disableShuffle') {
        self.props.disableShuffle(true);
      }
      if (item.type == 'play_progress') {
        self.seekPlayer(item.data.ev, item.data.value);
      }

      if (item.type == 'stationToggle') {
        self.props.disableShuffle(true);

        if (item.playType == 'station') self.props.setStationMode(item.data);
        else if (item.playType == 'session') self.props.setSessionMode(item.data);

        if (item.mode == 'playNow') {
          if (self.player) {
            self.player?.pause();
          }

          if (self.state.activespotplayer) {
            self.state.activespotplayer.pause();
            self.spotifyTimerToggle(false);
          }

          self.props.setQueue([]);
          self.props.setActiveSong(false);
        } else {
          let tempQueue = self.props.audiolist;
          tempQueue = tempQueue.slice(0, 1);
          self.props.setQueue(tempQueue);
        }
      }

      if (item.type == 'playNext') {
        let audioitem = item;
        audioitem['type'] = item['tracktype'] ? item['tracktype'] : 'normal';
        audioitem['id'] = item['sid'];

        if (item.altPage) {
          let tempQueue = self.props.audiolist;
          tempQueue = tempQueue.filter((ite) => ite.id !== item.sid);
          self.props.setQueue(tempQueue);
        }
        // self.sendAddToQueueEvent({ type: audioitem.type, id: item.sid })

        self.props.playNext(audioitem);
      }

      if (item.type == 'teamBrand') {
        self.loadAudio();
        self.setState({ teamBranding: true });
        let audioitem = item;
        const playSongData = {
          alink: audioitem.link,
          asid: 1,
          tag: true,
          newtitle: audioitem.title,
          newimage: audioitem.image,
          duration: audioitem.length ? audioitem.length : false,
          type: audioitem.tracktype ? audioitem.tracktype : 'normal',
          recent: true,
        };
        await self.playSong.call(self, playSongData);
        self.playIntro.call(self, audioitem.announcer);
        const activeSong = self.props.activesong;
        const nextSong = self.props.audiolist[1];
        self.nextAudio.call(self, 1, activeSong, nextSong);
      }

      if (item.type == 'playNow') {
        const audioitem = item;

        // player scenario only for alternate audio one page
        if (item.altPage) {
          let tempQueue = self.props.audiolist;
          tempQueue = tempQueue.filter((ite) => ite.id !== item.sid);
          self.props.setQueue(tempQueue);
        }

        let link = audioitem.link;
        var sid = audioitem.sid;

        if (self.props.activesong.id && !self.props.activesong.disableAd) {
          if (self.player) {
            self.player?.pause();
            if (self.secondaryPlayer) self.secondaryPlayer.pause();
          }

          if (self.state.activespotplayer) {
            self.state.activespotplayer.pause();
            self.spotifyTimerToggle(false);
          }

          var tempitem = self.props.audiolist.findIndex((item) => item.id === self.props.activesong.id);

          if (self.props.activesong.type !== 'spotify')
            self.saveAudioHistory({
              ...self.props.audiolist[tempitem],
              interruption: true,
            });
          else
            self.saveMusicHistory({
              ...self.props.audiolist[tempitem],
              interruption: true,
            });
        } else {
          self.playIntro.call(
            self,
            "You're listening to Audio One radio.  Music, news and podcasts in one playlist.  It's just better together."
          );
          // self.sendAddToQueueEvent({ type: audioitem.type, id: sid })
        }

        self.loadAudio();

        if (self.props.audiolist.find((item) => item.id === sid)) {
          self.props.playNow(sid);
        }

        // if (audioitem.subtype == "extra") {
        //   self.props.addPlaylist({
        //     title: audioitem.title,
        //     desc: "",
        //     link,
        //     image: audioitem.image,
        //     id: sid,
        //     length: 0,
        //     tag: true,
        //     type: "normal",
        //     subtype: audioitem.subtype,
        //   });
        // }

        if (audioitem.commentary) await self.playCommentary.call(self, audioitem.commentary);

        if (item.subtype == 'music') {
          let link = audioitem.link;
          let sid = audioitem.sid;
          let title = audioitem.title;
          let image = audioitem.image;
          let duration = audioitem.duration;

          const playSongData = {
            alink: link,
            asid: sid,
            tag: this.spotplayer,
            newtitle: title,
            newimage: image,
            duration: duration,
            type: 'spotify',
            recent: audioitem.recent,
          };

          self.playSong.call(self, playSongData);
          if (!self.state.activespotplayer) await self.createSpotify(item.token).then((res) => res);
          self.playSpotifyTrack(link);
        } else if (item.subtype == 'video') {
          const playSongData = {
            alink: link,
            asid: sid,
            tag: true,
            newtitle: audioitem.title,
            newimage: audioitem.image,
            duration: audioitem.length ? audioitem.length : false,
            type: audioitem.tracktype ? audioitem.tracktype : 'normal',
            recent: true,
          };
          await self.playSong.call(self, playSongData);
        } else {
          const prevDuration = self.player.getAttribute('data-duration');

          let load = self.createAudio.call(self, self.props.volume, true);
          load.src = link;

          if (prevDuration !== null && prevDuration >= 0) {
            load.setAttribute('data-duration', prevDuration);
            load.setAttribute('data-type', 'playprev');
          }

          if (item.tracktype == 'podcast' && item['summary']) {
            load.setAttribute('data-preview', 'podcast');
            sid = `${sid}preview`;
          }

          if (audioitem.subtype == 'extra') load.setAttribute('data-extra', 'extra');
          // load.load()

          // commenting this for now, in case we need to play silence audio in future between elements. Do not delete
          // load.addEventListener('loadstart', async (e) => {
          //   if (self.state.introPlayingEnded && !self.intermediateMusicPlaying) {
          //     self.playIntermediateMusic()
          //   }
          // })

          const playSongData = {
            alink: link,
            asid: sid,
            tag: true,
            newtitle: audioitem.title,
            newimage: audioitem.image,
            duration: audioitem.length ? audioitem.length : false,
            type: audioitem.tracktype ? audioitem.tracktype : 'normal',
            recent: true,
            isPost: audioitem.isPost,
            subtype: audioitem.subtype,
            disableAd: audioitem.disableAd,
            mainId: audioitem.mainId,
          };

          await self.playSong.call(self, playSongData);

          // if (self.state.introPlayingEnded) {
          //   load.addEventListener('loadedmetadata', async (e) => {
          //     self.stopAnnouncer.call(self)
          //   })
          // } else {
          //   self.stopAnnouncer.call(self)
          // }
        }
      }

      if (item.type == 'skipToChapter') {
        const audioitem = item.track;
        if (self.player) {
          self.player?.pause();
        }

        let load = self.createAudio(self.props.volume);
        const isPost = self.props.activesong.isPost;
        let curIndex = isPost.storyLines.findIndex((item) => item._id == audioitem._id);
        if (isPost.storyLines[curIndex]) {
          // if (isPost.storyLines[curIndex + 1].image)
          self.props.changeActiveImage({
            image: isPost.storyLines[curIndex].image,
            link: isPost.storyLines[curIndex].speechUrl,
            _id: isPost.storyLines[curIndex]._id,
          });

          load.src = isPost.storyLines[curIndex].speechUrl;
          load.load();
          load.play();
          return;
        }
      }
      if (item.type == 'newaudio') {
        const audioitem = item;
        let link = audioitem.link;
        let sid = audioitem.sid;
        this.audioItem = item;

        if (!self.props.activesong.id || self.props.activesong.disableAd || audioitem.recent) {
          const endingIndex = self.props.audiolist.findIndex((item) => item.id == audioitem.sid);
          const startIndex = self.props.audiolist.findIndex((item) => item.id == self.props.activesong.id);
          let ItemsInBetween = self.props.audiolist.slice(startIndex, endingIndex);
          ItemsInBetween.map((item) => {
            self.sendSkipEvent(item);
          });
          if (self.player) {
            self.player?.pause();
            if (self.secondaryPlayer) self.secondaryPlayer.pause();
          }

          if (self.state.activespotplayer) {
            self.state.activespotplayer.pause();
            self.spotifyTimerToggle(false);
          }

          if (self.props.activesong.id && self.props.activesong.type == 'podcast') {
            var tempitem = self.props.audiolist.findIndex((item) => item.id === self.props.activesong.id);
            self.saveAudioHistory({
              ...self.props.audiolist[tempitem],
              interruption: true,
            });
          }

          let curDuration = self.player?.currentTime;

          self.loadAudio();
          let isPost = audioitem.isPost
            ? audioitem.isPost.type
              ? audioitem.isPost.type == 'STORY' && audioitem.isPost.storyLines.length > 0
                ? {
                    type: 'STORY',
                    storyLines: audioitem.isPost.storyLines,
                    background: audioitem.storyBackground,
                  }
                : ''
              : true
            : '';

          const prevDuration = self.player.getAttribute('data-duration');

          let load = self.createAudio.call(self, self.props.volume, true);

          if (prevDuration !== null && prevDuration >= 0) {
            if (this.storedAudioItemId === audioitem.sid) {
              load.setAttribute('data-duration', prevDuration);
              load.setAttribute('data-type', 'playprev');
              this.storedAudioItemId = null;
            }
          }

          if (isPost && isPost.type == 'STORY') {
            load.src = isPost.storyLines[0].speechUrl;
            link = isPost.storyLines[0].speechUrl;
          } else load.src = link;

          if (self.props.activesong.id && audioitem.preview) {
            if (audioitem.subtype == 'extra') load.setAttribute('data-subtype', 'extra');

            load.setAttribute('data-type', 'playNow');
            load.setAttribute('data-duration', curDuration);
            this.storedAudioItemId = self.props.activesong.id;
          }
          // load.load()

          const playSongData = {
            alink: link,
            asid: sid,
            tag: true,
            newtitle: audioitem.title,
            newimage: audioitem.image,
            duration: audioitem.length ? audioitem.length : false,
            type: audioitem.tracktype ? audioitem.tracktype : 'normal',
            recent: audioitem.recent,
            isPost: audioitem.isPost,
            subtype: audioitem.subtype,
            mainId: audioitem.mainId,
          };

          await self.playSong.call(self, playSongData);

          if (audioitem.subtype === SUBTYPE_CONSTANTS.ADVERTISEMENT) {
            window.parent.postMessage(
              {
                type: COMMUNICATION_CONSTANTS.PLAY_GUEST_USER_ADVERTISEMENT,
                id: audioitem.id || sid,
                advertisementType: audioitem.advertisementType,
              },
              '*'
            );
          }

          let content = "You're listening to Audio One radio.  Music, news and podcasts in one playlist.  It's just better together.";
          let playType = false;
          if (self.props.stationMode) {
            content = self.props.stationMode;
            playType = 'station';
          }

          if (audioitem.subtype !== SUBTYPE_CONSTANTS.ADVERTISEMENT) {
            self.playIntro.call(self, content, false, playType);
          }

          if (audioitem.commentary) await self.playCommentary.call(self, audioitem.commentary);

          // commenting this for now, in case we need to play silence audio in future between elements. Do not delete
          // load.addEventListener('loadstart', async (e) => {
          //   if (self.state.introPlayingEnded && !self.intermediateMusicPlaying) {
          //     self.playIntermediateMusic()
          //   }
          // })

          if (audioitem.storyBackground) {
            self.secondaryPlayer = new Audio();
            self.secondaryPlayer.preload = 'auto';
            self.secondaryPlayer.src = audioitem.storyBackground;
            self.secondaryPlayer.load();
            self.secondaryPlayer.volume = 1;
            self.secondaryPlayer.loop = true;
            self.secondaryPlayer.play();
            load.volume = 0;
            setTimeout(() => {
              $(load).animate({ volume: 1 }, 500);
              load.play();
            }, 8000);
          }

          // if (self.state.introPlayingEnded) {
          //   load.addEventListener('loadedmetadata', async () => {
          //     self.stopAnnouncer.call(self)
          //   })
          // }
        } else {
          // self.sendAddToQueueEvent({ type: audioitem.type, id: audioitem.sid })
          if (audioitem.podcast || audioitem.shorts) {
            const playlistData = {
              alink: link,
              asid: sid,
              tag: true,
              newtitle: audioitem.title,
              newimage: audioitem.image,
              duration: audioitem.length,
              type: audioitem.tracktype,
              disableAd: audioitem.disableAd,
              mainId: audioitem.mainId,
            };
            self.addtoPlaylist.call(self, playlistData);
          } else {
            const playlistData = {
              alink: link,
              asid: sid,
              tag: true,
              newtitle: audioitem.title,
              newimage: audioitem.image,
              duration: false,
              type: 'normal',
              isPost: audioitem.isPost,
              disableAd: audioitem.disableAd,
              mainId: audioitem.mainId,
            };
            self.addtoPlaylist.call(self, playlistData);
          }
        }
        // self.stopAnnouncer.call(self)
        return;
      }

      if (item.type == 'videoTimer') {
        const { duration, remTime } = item;
        self.setYoutubeTimer(remTime, duration);
      }

      if (item.type == 'playVideo') {
        const audioitem = item;

        let link = audioitem.link;
        let sid = audioitem.sid;
        if (!self.props.activesong.id || audioitem.recent) {
          if (self.player) {
            self.player?.pause();
          }

          if (self.state.activespotplayer) {
            self.state.activespotplayer.pause();
            self.spotifyTimerToggle(false);
          }

          self.loadAudio();

          const playSongData = {
            alink: link,
            asid: sid,
            tag: true,
            newtitle: audioitem.title,
            newimage: audioitem.image,
            duration: audioitem.length ? audioitem.length : false,
            type: audioitem.tracktype ? audioitem.tracktype : 'normal',
            recent: audioitem.recent,
            mainId: audioitem.mainId,
          };

          await self.playSong.call(self, playSongData);

          let content = "You're listening to Audio One radio.  Music, news and podcasts in one playlist.  It's just better together.";
          let playType = false;

          self.playIntro.call(self, content, false, playType);
        } else {
          const playlistData = {
            alink: link,
            asid: sid,
            tag: true,
            newtitle: audioitem.title,
            newimage: audioitem.image,
            duration: audioitem.length,
            type: audioitem.tracktype,
            isPost: false,
            disableAd: audioitem.disableAd,
            mainId: audioitem.mainId,
          };

          self.addtoPlaylist.call(self, playlistData);
        }
        return;
      }

      if (item.type == 'videoEnded') {
        self.audioFinished();
      }

      if (item.type == 'playQueue') {
        if (!self.state.introPlayingEnded) {
          return;
        }
        const track = self.props.audiolist.find((ite) => ite.id == item.data.id);

        self.setPodcastDuration.call(self);

        if (track.id == self.props.activesong.id) {
          if (track.type === 'spotify') {
            if (self.state.activespotplayer) self.state.activespotplayer.togglePlay();
          } else if (track.type !== 'youtube') {
            if (self.props.playing) {
              self.player?.pause();
              self.sendPauseEvent();
              if (self.secondaryPlayer) {
                self.secondaryPlayer.pause();
              }
            } else {
              self.player?.play();
              self.sendPlayEvent();
              if (self.secondaryPlayer) {
                self.secondaryPlayer.play();
              }
            }
          }
          self.props.toggleSong(!self.props.playing);
        } else {
          if (self.props.activesong.type === 'spotify') {
            if (self.state.activespotplayer) {
              self.state.activespotplayer.pause();
              // self.state.activespotplayer.seek(0);
            }
          } else {
            if (self.player) {
              self.player?.pause();
              self.player.currentTime = 0;
            }
            if (self.secondaryPlayer) {
              self.secondaryPlayer.pause();
              self.secondaryPlayer.currentTime = 0;
            }
          }

          if (track.type !== 'spotify') {
            if (!self.props.activesong.id && track.type == 'podcast') {
              await self.upcomingAudio.call(self, track.title, 'continue');
            } else {
              self.playIntro.call(
                self,
                "You're listening to Audio One radio.  Music, news and podcasts in one playlist.  It's just better together."
              );
            }
          }
          if (track.commentary) await self.playCommentary.call(self, track.commentary);

          self.props.toggleSong(true);
          self.loadAudio();
          self.props.setActiveSong(track);

          if (track.type === 'spotify') {
            self.spotifyTimerToggle(false);
            if (!self.state.activespotplayer) await self.createSpotify(item.token).then((res) => res);
            self.state.activespotplayer.loadTrack(track.link);
          } else if (track.type !== 'youtube') {
            let load = self.createAudio(self.props.volume);
            load.src = track.link;
            load.load();
            load.play();
          }
        }
      }

      // // Triggered when spotify music play button is clicked
      if (item.type == 'spotifyplay') {
        var audioitem = item;

        self.setPodcastDuration.call(self);
        // To trigger player intro
        self.spotifyTimerToggle.call(self, false);
        if (self.player) {
          self.player?.pause();
          if (self.secondaryPlayer) {
            self.secondaryPlayer?.pause();
            self.secondaryPlayer.currentTime = 0;
          }
        }

        if (self.state.activespotplayer) {
          self.state.activespotplayer.pause();
        }

        self.loadAudio();

        let link = audioitem.link;
        let sid = audioitem.sid;
        let title = audioitem.title;
        let image = audioitem.image;
        let duration = audioitem.duration;

        const playSongData = {
          alink: link,
          asid: sid,
          tag: this.spotplayer,
          newtitle: title,
          newimage: image,
          duration: duration,
          type: 'spotify',
          recent: audioitem.recent,
        };

        self.playSong.call(self, playSongData);
        window.localStorage.setItem('refreshToken', item.refreshToken);
        if (!self.state.activespotplayer) await self.createSpotify(item.token).then((res) => res);
        self.playSpotifyTrack(link);
        return;
      }

      // Trigger when spotify music queue button is clicked
      if (item.type == 'spotifyaddplay') {
        // To trigger player intro
        var audioitem = item;
        let link = audioitem.link;
        let sid = audioitem.sid;
        let title = audioitem.title;
        let image = audioitem.image;
        let token = audioitem.token;
        let duration = audioitem.duration;

        if (!self.props.activesong.id) {
          if (!self.state.activespotplayer) await self.createSpotify(item.token).then((res) => res);

          self.spotifyTimerToggle(false);

          self.loadAudio();

          self.playSpotifyTrack(link);

          const playSongData = {
            alink: link,
            asid: sid,
            tag: this.spotplayer,
            newtitle: title,
            newimage: image,
            duration: duration,
            type: 'spotify',
            recent: audioitem.recent,
            disableAd: audioitem.disableAd,
          };

          self.playSong.call(self, playSongData);
        } else {
          const playlistData = {
            alink: link,
            asid: sid,
            tag: this.spotplayer,
            newtitle: title,
            newimage: image,
            duration: duration,
            type: 'spotify',
            isPost: false,
            disableAd: audioitem.disableAd,
            mainId: audioitem.mainId,
          };

          self.addtoPlaylist.call(self, playlistData);
        }

        return;
      }
      // Triggered when spotify playlist is clicked
      if (item.type == 'spotifylist') {
        var items = item.tracks;
        window.localStorage.setItem('refreshToken', item.refreshToken);
        self.isGoingToPlaySpotify = true;

        for (const item of items) {
          const audioItem = self.props.audiolist.find((audio) => item.id === audio.id);

          if (audioItem) {
            items = items.filter((v) => v.id !== audioItem.id);
          }
        }

        if (item.subtype == 'playNow') {
          self.processSpotifyListPlayNow(items);
          // self.props.setQueue([])
          // self.props.disableShuffle(true)
          return;
        }

        var addTracks = async () =>
          await Promise.all(
            items.map(async (item, index) => {
              const playlistData = {
                alink: item.url,
                asid: item.id,
                tag: this.player,
                newtitle: item.title,
                newimage: item.image,
                duration: item.duration,
                type: 'spotify',
                isPost: false,
                disableAd: item.disableAd,
                mainId: item.mainId,
              };

              await self.addtoPlaylist.call(self, playlistData);
            })
          );

        let activeindex = self.props.audiolist.findIndex((item) => item.id === self.props.activesong.id);
        let currentarr = self.props.audiolist.slice(activeindex + 1);

        const oldList = currentarr.filter((item) => item.type == 'spotify');
        var data = await addTracks().then((res) => console.log(res));

        var newList = self.props.audiolist.filter((item) => items.find((ite) => ite.id == item.id));

        let newAudioList = [];
        const listlength = oldList.length + newList.length;
        for (var i = 0; i <= listlength; i += 6) {
          const oldArr = oldList.slice(i, i + 6);
          const newArr = newList.slice(i, i + 6);

          let tempArr = [...oldArr, ...newArr];
          newAudioList.push(...tempArr);
        }
        self.props.shuffleSpotifyList(newAudioList);

        if (!self.state.activespotplayer) await self.createSpotify(item.token).then((res) => res);
        if (!self.props.activesong.id || item.subtype == 'playNow') {
          if (self.player) {
            self.player?.pause();
          }

          if (self.state.activespotplayer) {
            self.state.activespotplayer.pause();
            self.spotifyTimerToggle(false);
          }

          self.loadAudio();
          self.spotifyTimerToggle(false);

          const trackid = newAudioList[0].id;
          var newaud = self.props.audiolist.find((item) => item.id == trackid);
          self.props.setActiveSong(newaud);

          self.spotifyPlaySongLink = newaud.link;

          // self.playSpotifyTrack(newaud.link)
        } else {
        }
        return;
      }

      if (item.type == 'changeTheme') {
        if (item.theme == 'black-theme')
          self.setState({
            theme: 'black-theme',
          });
        else
          self.setState({
            theme: 'white-theme',
          });
      }
      if (item.type == 'updateQueue') {
        self.props.updateListOrder(item.order);
      }

      if (item.type == 'setAudioHistory') {
        self.props.setAudioHistory(item.data);
      }
      if (item.type == 'updateRecentPlayed') {
        self.props.setRecentPlayed(item.tracks);
      }
      if (item.type == 'lastPlayed') {
        self.props.setLastPlayed(item.data);
      }

      if (item.type == 'removeItem') {
        const tempitem = self.props.audiolist.filter((ite) => ite.id !== item.track.id);
        const activeSong = self.props.activesong;

        var nextItem = self.props.audiolist.findIndex((item) => item.id === activeSong.id);
        var index = nextItem + 1;
        const nextSong = self.props.audiolist[index];

        if (item.track.type == 'normal') self.markArticleAsRead(item.track);

        self.props.removePlaylist(tempitem);
        if (item.podcastremove) {
          let tempobj = item.track;
          tempobj.completed = true;
          let newItems = self.props.recentPlayed.map((ite) => {
            if (ite.id == tempobj.id) item['completed'] = true;
            return ite;
          });
          self.props.setRecentPlayed([tempobj, ...newItems]);
        }

        if (item.track.id === activeSong.id) {
          self.props.setActiveSong(false);
          self.nextAudio.call(self, index, activeSong, nextSong);
        }
      }

      if (item.type === COMMUNICATION_CONSTANTS.ADD_GUEST_USER_ADVERTISEMENT) {
        self.props.addPlaylist(self.getAdvertisementData.call(self, item));
      }
      // }
    });
    this.player = this.createAudio(1);
  }

  resetCompletePlayer() {
    this.props.resetPlayer();
    if (this.player) {
      this.player?.pause();
      this.player.currentTime = 0;
      this.player = this.createAudio(1);
    }
    if (this.introAudio) {
      this.introAudio.pause();
      this.introAudio = null;
    }
    this.stopSilenceAudio();
  }

  playGuestUserAdvertisement(item) {
    var self = this;
    const load = self.createAudio.call(self, 1);
    load.src = item.link;
    load.load();
    load.play();
    window.parent.postMessage(
      {
        type: COMMUNICATION_CONSTANTS.PLAY_GUEST_USER_ADVERTISEMENT,
        id: item.id || item.sid,
        advertisementType: item.advertisementType,
      },
      '*'
    );
  }

  updateAdvertisementState(item) {
    var self = this;
    const advertisementVisible = !!(item && item.advertisementVisible === 'true');
    self.setState({
      advertisementCookieSet: advertisementVisible,
      // isInitHeaderInitiated: true
    });
  }

  nextAudio(index, activeSong, nextSong) {
    if (activeSong.type === 'spotify') {
      if (this.state.activespotplayer) {
        this.state.activespotplayer.seek(0);
        this.state.activespotplayer.pause();
      }
    } else {
      if (this.player) {
        this.player.pause();
        this.player.currentTime = 0;
      }
    }

    if (typeof this.props.audiolist[index] === 'undefined') {
      this.props.setActiveSong(false);
      this.props.toggleSong(false);
      this.setState({
        remTime: '',
        totaltime: '',
      });
    } else {
      var newaud = nextSong;
      this.props.setActiveSong(newaud);
      this.props.toggleSong(true);

      if (newaud.type === 'spotify') {
        this.spotifyTimerToggle(false);
        this.state.activespotplayer.loadTrack(newaud.link);
      } else if (newaud.type !== 'youtube') {
        if (this.player) {
          this.player?.pause();
          this.player.currentTime = 0;
        }
        let load = this.createAudio(this.props.volume);
        load.src = newaud.link;
        load.load();
        load.play();
      }
    }
  }

  stopAnnouncer() {
    var self = this;
    if (self.intermediateMusicPlaying) {
      self.intermediateMusicContext.pause();
      self.intermediateMusicContext = null;
      self.intermediateMusicPlaying = false;
    }
  }

  createAudio(volume, initialisePlay = false) {
    var self = this;
    self.metadataLoaded = false;
    var aud = new Audio();
    // aud.preload = 'auto'
    aud.volume = volume;
    aud.preload = 'auto';

    delete this.player;
    this.player = aud;
    this.initialisePlay = initialisePlay;

    if (!this.player) {
      return;
    }

    this.player.ontimeupdate = function (event) {
      self.setState((prevState) => {
        if (prevState.announcerPlaying) self.player?.pause();
        return prevState;
      });

      self.currentTime = event.currentTarget.currentTime;

      self.setTimings.call(self, event.currentTarget.currentTime, event.currentTarget.duration);
      self.isSpotifyPlayerPlaying = false;
    };

    this.player.onended = function () {
      self.audioFinished.call(self);
    };

    this.player.onloadedmetadata = async function (e, initialisePlay) {
      if (self.intermediateMusicPlaying && self.intermediateMusicContext) {
        let newPercent = 1;
        const timer = setInterval(async () => {
          if (newPercent >= 0) {
            self.intermediateMusicContext.volume = newPercent;
            newPercent = parseFloat((newPercent - 0.05).toFixed(2));
          } else {
            clearInterval(timer);
            self.intermediateMusicContext.volume = 0;
            self.metadataLoadedCalls();
          }
        }, 100);
      } else {
        self.metadataLoadedCalls();
      }
    };

    return aud;
  }

  async metadataLoadedCalls() {
    const self = this;
    self.stopAnnouncer.call(self);
    self.metadataLoaded = true;
    // To trigger dj for upcoming podcast
    let startedPlaying = false;
    if (self.player.getAttribute('data-preview') == 'podcast') {
      self.player.currentTime = 60;

      const timeoutId = setTimeout(() => {
        if (self.player) {
          self.player?.pause();
          self.player.currentTime = 0;
        }

        self.audioFinished();
        clearTimeout(timeoutId);
      }, 30000);
      return;
    }

    var newaud = self.props.activesong;
    if (newaud.isPost) {
      console.log("don't do upcoming if its a story");
    } else {
      var activePodcast = [...self.props.audioHistory.filter((item) => item.id == newaud.id)];
      activePodcast.push(...self.props.recentPlayed.filter((item) => item.id == newaud.id));
      activePodcast = activePodcast.sort((a, b) => {
        return b['updated'] - a['updated'];
      });

      activePodcast = activePodcast[0];
      if (!self.player?.paused || self.intermediateMusicContext) {
        var index = self.props.audiolist.findIndex((item) => item.id === self.props.activesong.id);

        // to skip the announcement on first track after team branding
        if (index == 0 && (self.state.teamBranding || self.state.introAnnouncerPlaying))
          if (self.props.audiolist[index + 3] !== undefined) {
            if (
              self.props.audiolist[index + 3].type === 'podcast' &&
              self.props.audiolist[index + 2].type !== 'podcast' &&
              self.props.activesong.type !== SUBTYPE_CONSTANTS.ADVERTISEMENT
            ) {
              self.player?.pause();
              self.stopAnnouncer.call(self);
              await self.upcomingAudio(self.props.audiolist[index + 3].title, true).then((res) => res);
              self.stopAnnouncer.call(self);
              self.player?.play();
              startedPlaying = true;
            }
          }

        if (activePodcast && activePodcast.type == 'podcast') {
          if (activePodcast.duration) {
            self.player?.pause();
            self.stopAnnouncer.call(self);
            await self.upcomingAudio(activePodcast.title, 'continue').then((res) => res);
            self.player?.play();
            startedPlaying = true;
          }
        }
      }
    }

    // to seek podacst duration
    if (activePodcast && (activePodcast.type == 'podcast' || activePodcast['interruption'])) {
      if (activePodcast['interruption']) {
        var tempitem = self.props.audiolist.findIndex((item) => item.id === self.props.activesong.id);
        self.saveAudioHistory(
          {
            ...self.props.audiolist[tempitem],
          },
          false
        );
      }
      if (activePodcast.duration) {
        self.seekTrack(activePodcast.duration);
      }
    }

    if (self.player.getAttribute('data-duration') && self.player.getAttribute('data-type') == 'playprev') {
      self.seekTrack(self.player.getAttribute('data-duration'));
      self.player.setAttribute('data-type', '');
    } else if (
      self.props.lastPlayed &&
      self.props.lastPlayed.type !== 'spotify' &&
      self.props.lastPlayed.track_id &&
      self.props.lastPlayed.track_id == newaud.id
    ) {
      self.seekTrack(self.props.lastPlayed.duration);
    }

    if (!startedPlaying && self.initialisePlay && this.state.introPlayingEnded) {
      self.player?.play();
    }
  }

  // To play or add to que news or music
  async addtoPlaylist(playlistData) {
    const {
      alink,
      asid,
      tag,
      newtitle = false,
      newimage = false,
      duration = false,
      type = 'normal',
      isPost = false,
      disableAd = false,
      mainId = '',
      recent = false,
    } = playlistData || {};

    var desc = 'desc';
    var link = alink;
    var sid = asid;
    var length = 0;
    if (type !== 'normal') length = duration;
    var image = newimage;
    var self = this;
    // if (type === "normal") {
    //   const anchor = document.createElement("a");
    //   anchor.href = alink;
    //   var temptitle = anchor.pathname;
    // }

    if (type !== 'normal') {
      if (!self.props.audiolist.find((item) => item.id === sid))
        self.props.addPlaylist({
          title: newtitle,
          desc,
          link,
          image: newimage,
          id: sid,
          length,
          tag,
          type: type,
          isPost,
          disableAd,
          mainId,
          recent,
        });

      return;
    }
    if (!self.props.audiolist.find((item) => item.id === sid)) {
      self.props.addPlaylist({
        title: newtitle,
        desc,
        link,
        image,
        id: sid,
        length,
        tag,
        type: type,
        isPost,
        disableAd,
        mainId,
        recent,
      });
    }

    //   if (!scramble)
    // else
    //   self.props.scramblePlaylist({
    //     title: temptitle,
    //     desc,
    //     link,
    //     image,
    //     id: sid,
    //     length,
    //     tag,
    //     type: type
    //   });
  }

  async processSpotifyListPlayNow(items) {
    if (!items || !items.length) {
      return;
    }

    if (this.props.activesong.id && !this.props.activesong.disableAd) {
      if (this.player) {
        this.player?.pause();
        if (this.secondaryPlayer) this.secondaryPlayer.pause();
      }

      if (this.state.activespotplayer) {
        this.state.activespotplayer.pause();
        this.spotifyTimerToggle(false);
      }
    }

    const reversedItems = [...items].reverse();
    // reversing because: otherwise it adds the first element at last because it keeps adding the items one by one into array and the first element pushed to last
    (reversedItems || []).map(async (item, index) => {
      const playlistData = {
        alink: item.url,
        asid: item.id,
        tag: this.spotplayer,
        newtitle: item.title,
        newimage: item.image,
        duration: item.duration,
        type: 'spotify',
        isPost: false,
        disableAd: item.disableAd,
        mainId: item.mainId,
        recent: true,
      };

      await this.addtoPlaylist(playlistData);
    });

    const lastItem = items[items.length - 1];
    const firstItem = items[0];

    const playSongData = {
      alink: firstItem.url,
      asid: firstItem.id,
      tag: this.spotplayer,
      newtitle: firstItem.title,
      newimage: firstItem.image,
      duration: firstItem.duration,
      type: 'spotify',
      recent: true,
    };

    this.playSong(playSongData);

    const lastItemIndex = this.props.audiolist.findIndex((item) => item.id == lastItem.id);

    const unprocessedAudioList = this.props.audiolist.slice(0, lastItemIndex + 1);
    const toBeProcessedAudioList = this.props.audiolist.slice(lastItemIndex + 1, this.props.audiolist.length);

    const withoutSpotifyTracksList = (toBeProcessedAudioList || []).filter((it) => it.type !== 'spotify');

    const updatedTrackList = (unprocessedAudioList || []).concat(withoutSpotifyTracksList);

    this.props.setQueue(updatedTrackList);

    if (!this.state.activespotplayer) {
      await this.createSpotify(lastItem.token).then((res) => res);
    }

    this.playSpotifyTrack(firstItem.url);
  }

  // To play a song or news article
  async playSong(playSongData) {
    const {
      alink,
      asid,
      tag,
      newtitle = false,
      newimage = false,
      duration = false,
      type = 'normal',
      recent = false,
      isPost = false,
      subtype = false,
      disableAd = false,
      mainId = '',
    } = playSongData || {};

    var desc = 'abc';
    var link = alink;
    var sid = asid;
    var length = 0;
    if (type !== 'normal') length = duration;
    var image = newimage;
    var self = this;

    // if (type !== "normal") {
    //   if (!self.props.audiolist.find((item) => item.id === sid))
    //     self.props.addPlaylist({
    //       title: newtitle,
    //       desc,
    //       link,
    //       image: newimage,
    //       id: sid,
    //       length,
    //       tag,
    //       type: type,
    //       recent: recent,
    //       isPost,
    //     });

    //   self.props.setActiveSong({
    //     title: newtitle,
    //     desc,
    //     link,
    //     image: newimage,
    //     id: sid,
    //     length,
    //     type: type,
    //     recent: recent,
    //     previousClicked: recent,
    //     isPost,
    //   });

    //   return;
    // }
    // self.sendAddToQueueEvent({ type: type, id: sid })

    // For adding normal tracks
    if (!self.props.audiolist.find((item) => item.id === sid))
      self.props.addPlaylist({
        title: newtitle,
        desc,
        link,
        image,
        id: sid,
        length,
        tag,
        type: type,
        recent: recent,
        isPost,
        subtype,
        disableAd,
        mainId,
      });
    self.props.setActiveSong({
      title: newtitle,
      desc,
      link,
      image,
      id: sid,
      length,
      type: type,
      previousClicked: recent,
      isPost,
      subtype,
      disableAd,
      mainId,
    });
  }

  // Trigger play and show player
  loadAudio() {
    if (!this.props.visible) this.props.toggleVisibility(true);

    if (!this.props.playing) this.props.toggleSong(true);
  }

  sendPauseEvent() {
    window.parent.postMessage(
      {
        type: 'TRACK_PAUSED',
        item: {
          duration: this.player?.currentTime,
          id: this.props.activesong.id,
          type: this.props.activesong.type == 'normal' ? 'news' : 'podcast',
        },
      },
      '*'
    );
  }

  sendPlayEvent() {
    window.parent.postMessage(
      {
        type: 'TRACK_PLAYED',
        item: {
          duration: this.player?.currentTime,
          id: this.props.activesong.id,
          type: this.props.activesong.type == 'normal' ? 'news' : 'podcast',
        },
      },
      '*'
    );
  }

  sendSkipEvent(item) {
    window.parent.postMessage(
      {
        type: 'TRACK_SKIPPED',
        item: {
          duration: this.player?.currentTime,
          id: item.id,
          type: item.type == 'normal' ? 'news' : 'podcast',
        },
      },
      '*'
    );
  }

  sendTrackEndedEvent() {
    window.parent.postMessage(
      {
        type: 'TRACK_ENDED',
        item: {
          duration: this.player?.currentTime,
          id: this.props.activesong.id,
          type: this.props.activesong.type == 'normal' ? 'news' : 'podcast',
        },
      },
      '*'
    );
  }

  // sendAddToQueueEvent({ type, id }) {
  //   window.parent.postMessage(
  //     {
  //       type: 'TRACK_ADDED',
  //       item: {
  //         duration: 0,
  //         id: id,
  //         type: type == 'normal' ? 'news' : 'podcast',
  //       },
  //     },
  //     '*'
  //   )
  // }

  seekTrack(pos) {
    if (this.isSpotifyPlayerPlaying) {
      this.seekSpotifyPosition(pos);
    } else {
      if (this.player) {
        this.player.currentTime = pos;
      }
    }
  }

  // To set timings for news articles
  async setTimings(currentTime, duration) {
    var self = this;
    if (!currentTime) {
      currentTime = self.currentTime || 0;
    }
    if (!self.player?.paused || (self.state.activespotplayer && self.isSpotifyPlayerPlaying)) {
      let trackposition = currentTime / duration;
      var totalTime = duration,
        currentTime = currentTime,
        remainingTime = totalTime - currentTime;
      var tminute = Math.floor(totalTime / 60); // get minute(integer) from time
      var ttmp = Math.round(totalTime - tminute * 60); // get second(integer) from time
      var tsecond = (ttmp < 10 ? '0' : '') + ttmp; // make two-figured integer if less than 10

      var rminute = Math.floor(remainingTime / 60); // get minute(integer) from time
      var rtmp = Math.round(remainingTime - rminute * 60); // get second(integer) from time
      var rsecond = (rtmp < 10 ? '0' : '') + rtmp; // make two-figured integer if less than 10

      totalTime = String(tminute + ':' + tsecond) === 'NaN:NaN' ? '' : String(tminute + ':' + tsecond);
      remainingTime = String(rminute + ':' + rsecond) === 'NaN:NaN' ? '' : String(rminute + ':' + rsecond);

      if (!self.seekInProgress) {
        window.parent.postMessage(
          {
            type: 'playProgress',
            totaltime: totalTime,
            remtime: remainingTime,
            waveposition: trackposition,
          },
          '*'
        );

        self.setState({
          totaltime: totalTime,
          remtime: remainingTime,
          waveposition: trackposition,
        });
      }
    }
  }

  // To toggle repeat mode
  toggleShuffle() {
    this.props.toggleShuffle(!this.props.shuffle);
  }

  // Toggle the player view
  async toggleVisibility() {
    var obj = {
      type: 'playertoggle',
    };
    var url = window.location !== window.parent.location ? document.referrer : document.location.href;
    // Change this domain to the parent window domain
    var arr = url.split('/');
    var result = arr[0] + '//' + arr[2];
    window.parent.postMessage(obj, result);

    this.props.toggleVisibility(!this.props.visible);
  }

  // Toggle the playlist view
  toggleList(playlist) {
    var condition = true;

    if (playlist == true) {
      if (!this.props.playlist && this.props.search) condition = false;
    } else {
      if (!this.props.search && this.props.playlist) condition = false;
    }

    if (condition) {
      var obj = {
        type: 'playlisttoggle',
      };
      var url = window.location != window.parent.location ? document.referrer : document.location.href;
      // Change this domain to the parent window domain
      var arr = url.split('/');
      var result = arr[0] + '//' + arr[2];

      window.parent.postMessage(obj, result);
    }

    if (playlist == true) {
      this.props.togglePlaylist(!this.props.playlist);
      this.props.toggleSearch(false);
    } else {
      this.props.toggleSearch(!this.props.search);
      this.props.togglePlaylist(false);
    }
  }

  setPodcastDuration() {
    if (this.props.activesong.id)
      if (this.props.activesong.type == 'podcast')
        this.props.setDuration({
          trackId: this.props.activesong.id,
          duration: this.player.currentTime,
        });
  }

  markArticleAsRead(article) {
    window.parent.postMessage(
      {
        type: 'markArticleAsRead',
        article,
      },
      '*'
    );
  }

  // Detect end of a news article
  async audioFinished() {
    const self = this;

    const prevTrack = this.props.activesong;
    self.sendTrackEndedEvent();

    this.props.setCompleted(prevTrack);

    var tempitem = this.props.audiolist.findIndex((item) => item.id === this.props.activesong.id);

    var index = tempitem + 1;
    this.setPodcastDuration();

    if (this.props.activesong.isPost) {
      if (this.props.activesong.isPost.type == 'STORY') {
        let load = this.createAudio(self.props.volume);
        let curLink = this.props.activesong.link;
        const isPost = this.props.activesong.isPost;
        let curIndex = isPost.storyLines.findIndex((item) => item.speechUrl == curLink);
        if (isPost.storyLines[curIndex + 1]) {
          // if (isPost.storyLines[curIndex + 1].image)

          this.props.changeActiveImage({
            image: isPost.storyLines[curIndex + 1].image,
            link: isPost.storyLines[curIndex + 1].speechUrl,
            _id: isPost.storyLines[curIndex + 1]._id,
          });

          load.src = isPost.storyLines[curIndex + 1].speechUrl;
          load.load();
          load.play();

          return;
        }
      }
    }
    if (this.props.activesong.isPost.background) {
      if (self.secondaryPlayer) {
        const waitFor = (delay) =>
          new Promise((resolve) => {
            setTimeout(() => {
              self.secondaryPlayer.pause();
              self.secondaryPlayer.currentTime = 0;
            }, delay);
          });

        await waitFor(8000);
      }
    }
    if (prevTrack.type == 'normal') this.markArticleAsRead(prevTrack);
    var inHistory = false;
    if (this.player.getAttribute('data-type') == 'playNow') {
      const lastIndex = _.findLastIndex(self.props.audiolist, (item) => item.id == self.props.activesong.id);
      if (this.player.getAttribute('data-subtype')) {
        const lastItem = this.props.audiolist[lastIndex + 1];
        inHistory = lastItem;

        if (inHistory) {
          const { link, id, title, image, length, type, disableAd } = inHistory;
          const playSongData = {
            alink: link,
            asid: id,
            tag: true,
            newtitle: title,
            newimage: image,
            duration: length,
            type: type,
            disableAd: disableAd,
          };

          await self.playSong.call(self, playSongData);
        }
      } else {
        const lastItem = this.props.audiolist[lastIndex];

        for (let i = lastIndex; i >= 0; --i) {
          if (this.props.audiolist[i]['id'] !== lastItem['id']) {
            inHistory = self.props.audiolist[i];
            break;
          }
        }
        const { link, id, title, image, length, type, disableAd } = inHistory;
        const playSongData = {
          alink: link,
          asid: id,
          tag: true,
          newtitle: title,
          newimage: image,
          duration: length,
          type: type,
          disableAd: disableAd,
        };
        await self.playSong.call(self, playSongData);
      }
    }
    if (typeof this.props.audiolist[index] === 'undefined' && !inHistory) {
      this.props.setActiveSong(false);
      this.props.toggleSong(false);
      self.setState({
        remTime: '',
        totaltime: '',
      });
    } else {
      var newaud = this.props.audiolist[index];
      if (inHistory) newaud = inHistory;
      this.props.setActiveSong(newaud);

      if (newaud.type === 'advertisement') {
        self.playGuestUserAdvertisement.call(self, newaud);
      } else if (newaud.type === 'spotify') {
        self.spotifyTimerToggle(false);
        await this.playSpotifyTrack(newaud.link);
      } else if (newaud.type !== 'youtube') {
        let prevDuration = this.player.getAttribute('data-duration') ? this.player.getAttribute('data-duration') : false;
        let load = this.createAudio(self.props.volume);

        let isPost = newaud.isPost;
        if (isPost && isPost.type == 'STORY') {
          load.src = isPost.storyLines[0].speechUrl;
          this.props.changeActiveImage({
            image: isPost.storyLines[0].image,
            link: isPost.storyLines[0].speechUrl,
            _id: isPost.storyLines[0]._id,
          });
        } else load.src = newaud.link;
        if (prevDuration !== null && prevDuration >= 0 && inHistory) {
          load.setAttribute('data-duration', prevDuration);
          load.setAttribute('data-type', 'playprev');
        }
        load.load();

        if (isPost && isPost.background) {
          self.secondaryPlayer = new Audio();
          self.secondaryPlayer.src = isPost.background;
          self.secondaryPlayer.load();
          self.secondaryPlayer.volume = 1;
          self.secondaryPlayer.loop = true;
          self.secondaryPlayer.play();
          load.volume = 0;
          setTimeout(() => {
            load.play();
            $(load).animate({ volume: 1 }, 500);
          }, 8000);
        } else {
          load.play();
        }
      }
    }
  }

  async fetchAndSetToken() {
    var refreshed_token = await axios
      .post('https://spotify-authrization.herokuapp.com/refresh_token', {
        refresh_token: window.localStorage.getItem('refreshToken'),
      })
      .then((res) => res.data);
    return refreshed_token.access_token;
  }

  disconnectPlayer() {
    const { activespotplayer } = this.state;
    if (activespotplayer) {
      activespotplayer.disconnect();
      this.setState({ activespotplayer: null });
    }
  }

  // TO play a spotify tack
  async playSpotifyTrack(trackuri) {
    const self = this;
    // if (self.state.spotifyTokenRenewal) {
    //   self.setState({
    //     renewalProgress: true,
    //   });
    //   await self.createSpotify(self.state.spotifyToken);
    //   self.setState({
    //     renewalProgress: false,
    //     spotifyTokenRenewal: false,
    //   });
    // }

    await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${this.state.deviceId}`, {
      method: 'PUT',
      body: JSON.stringify({
        uris: [trackuri],
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.spotifyToken}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          // Expired token
          return this.fetchAndSetToken().then(async (token) => {
            await self.createSpotify(token);
            return self.playSpotifyTrack(trackuri);
          });
        } else if (res.status === 502) {
          // Expired token
          return this.fetchAndSetToken().then(async (token) => {
            await self.createSpotify(token);
            return self.playSpotifyTrack(trackuri);
          });
        } else if (res.status === 403) {
          alert.error('Error: Spotify Premium Required');
          return;
        } else if (res.status === 404) {
          console.log('error 404 on track PUT, trying to reinitialize');
          self.disconnectPlayer();
          const { spotifyToken } = self.state;
          return self.createSpotify(spotifyToken).then(() => {
            console.log('Spoitfy player reinitialized');
            return self.playSpotifyTrack(trackuri);
          });
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
  }

  async seekSpotifyPosition(position) {
    if (position === undefined) {
      return;
    }

    this.setState({
      wavePosition: position / this.spotifyContentDuration,
    });

    try {
      this.seekInProgress = true;
      await fetch(`https://api.spotify.com/v1/me/player/seek?position_ms=${position}&device_id=${this.state.deviceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.spotifyToken}`,
        },
      })
        .then((res) => {
          this.seekInProgress = false;
          if (res.status !== 204) {
            console.log('failed to seek');
          }
        })
        .catch(async (err) => {
          this.seekInProgress = false;
          console.log(err);
        });
    } catch (e) {}
  }

  // Create Spotify Player
  async createSpotify(token) {
    var newSpotify = new window.Spotify.Player({
      name: 'AudioOne Player',
      getOAuthToken: (cb) => {
        cb(token);
      },
    });

    newSpotify = await this.createEventHandlers(newSpotify).then((res) => {
      return res;
    });
    newSpotify.loadTrack = this.playSpotifyTrack.bind(this);

    this.setState({
      activespotplayer: newSpotify,
      spotifyToken: token,
    });

    return newSpotify;
  }

  // Attach handlers to spotify player
  async createEventHandlers(newSpotify) {
    var self = this;
    var spotifyTimer = false;
    newSpotify.on('initialization_error', (e) => {
      console.log(e);
    });

    newSpotify.on('ready', (e) => {
      console.log(e, 'ready state');
    });

    newSpotify.on('progress', (e) => {
      if (!e || e.position === undefined) {
        return;
      }

      self.currentSpotifyPosition = e.position;
      const currentTime = (e.position || 0) / 1000;
      const duration = self.spotifyContentDuration / 1000;
      self.setTimings.call(self, currentTime, duration);
      this.isSpotifyPlayerPlaying = true;
      // console.log(e, 'progress', self.spotifyContentDuration);
    });

    newSpotify.on('authentication_error', (e) => {
      console.log(e);
      this.setState({ loggedIn: false });
    });

    newSpotify.on('account_error', (e) => {
      console.log(e);
    });

    newSpotify.on('playback_error', (e) => {
      // if (self.state.renewalProgress) return;
      // To retry if a playback error occurs
      // if (self.state.spotifytries <= 1) {
      //   // self.playSpotifyTrack(self.state.activesong.link);
      //   self.setState((prevState) => ({
      //     spotifytries: prevState.spotifytries + 1,
      //   }));
      // } else {
      //   self.setState({
      //     spotifytries: 0,
      //   });
      //   var tempitem = self.props.audiolist.findIndex(
      //     (item) => item.id === self.props.activesong.id
      //   );
      //   var index = tempitem + 1;
      //   if (typeof self.props.audiolist[index] === "undefined") {
      //     self.props.toggleSong(false);
      //   } else {
      //     var newaud = self.props.audiolist[index];
      //     self.props.setActiveSong(newaud);
      //     if (newaud.type === "spotify") {
      //       self.playSpotifyTrack(newaud.link);
      //     } else if (newaud.type !== "youtube") {
      //       let load = this.createAudio(self.props.volume);
      //       load.src = newaud.link;
      //       if (newaud.isPost) this.playPost(newaud);
      //       else {
      //         load.load();
      //         load.play();
      //       }
      //     }
      //   }
      // }
    });

    // Playback status updates
    // newSpotify.on('player_state_changed', async (state) => {
    //   self.setState({
    //     spotifyState: state,
    //   })
    //   if (self.state.spotifyLoading) return

    //   self.spotifyContentDuration = state.duration || 0;

    //   console.log(state.position, 'state.position');
    //   // state.duration
    //   if (state.position === 0) {
    //     // To stop music if a news article is being played
    //     if (state.track_window.current_track.id !== self.props.activesong.id) {
    //       // self.state.activespotplayer.pause();
    //       self.state.activespotplayer.seek(0)
    //     }

    //     // To trigger dj mode
    //     if (
    //       !state.paused &&
    //       state.track_window.current_track.id === self.props.activesong.id
    //     ) {
    //       self.setState({
    //         spotifytries: 0,
    //       })

    //       const index = self.props.audiolist.findIndex(
    //         (item) => item.id === self.props.activesong.id
    //       )

    //       const newaud = self.props.activesong
    //       let activeTrack = [
    //         ...self.props.audioHistory.filter((item) => item.id == newaud.id),
    //       ]
    //       activeTrack.push(
    //         ...self.props.recentPlayed.filter((item) => item.id == newaud.id)
    //       )
    //       activeTrack = activeTrack.sort((a, b) => {
    //         return b['updated'] - a['updated']
    //       })

    //       activeTrack = activeTrack[0]

    //       // to seek podacst duration
    //       // if (activeTrack && activeTrack["interruption"]) {
    //       //   var tempitem = self.props.audiolist.findIndex(
    //       //     (item) => item.id === self.props.activesong.id
    //       //   );

    //       //   self.setState({ spotifyLoading: true });
    //       //   const duration = activeTrack.duration;
    //       //   await self.state.activespotplayer.seek(duration);

    //       //   self.saveMusicHistory(
    //       //     {
    //       //       ...self.props.audiolist[tempitem],
    //       //     },
    //       //     false
    //       //   );
    //       //   self.setState({ spotifyLoading: false });
    //       // }

    //       if (self.props.audiolist[index + 2] !== undefined) {

    //         if (self.props.audiolist[index + 2].type === 'normal') {
    //           if (!self.state.djplayed) {
    //             self.setState({
    //               djplayed: true,
    //             })
    //             await self.state.activespotplayer.setVolume(0.1)
    //             var djmode = await self
    //               .upcomingAudio(
    //                 'Top news Stories Coming Up Next. You are listening to Audio One radio.'
    //               )
    //               .then((res) => res)
    //               console.log('it entered here');
    //             fadeVolIn(self.state.activespotplayer, 0.1)
    //           }
    //         } else if (
    //           (self.props.audiolist[index + 2].type === 'shorts' ||
    //             self.props.audiolist[index + 2].type === 'normal') &&
    //           self.props.sessionMode
    //         ) {
    //           if (!self.state.djplayed) {
    //             self.setState({
    //               djplayed: true,
    //             })
    //             await self.state.activespotplayer.setVolume(0.1)
    //             var djmode = await self
    //               .upcomingAudio(
    //                 "You're listening to AudioOne radio. Up next, a short news break."
    //               )
    //               .then((res) => res)
    //             fadeVolIn(self.state.activespotplayer, 0.1)
    //           }
    //         } else if (self.props.audiolist[index + 2].type === 'podcast') {
    //           if (!self.state.djplayed) {
    //             self.setState({
    //               djplayed: true,
    //             })
    //             await self.state.activespotplayer.setVolume(0.1)
    //             var djmode = await self
    //               .upcomingAudio(self.props.audiolist[index + 2].title, true)
    //               .then((res) => res)
    //             fadeVolIn(self.state.activespotplayer, 0.1)
    //           }
    //         }
    //       }
    //     }

    //     // To trigger the timer for spotify tracks
    //     if (
    //       !state.paused &&
    //       self.state.spotifyTimer === false &&
    //       self.props.activesong.type === 'spotify'
    //     ) {
    //       var durationInMilliseconds =
    //         (Number(self.props.activesong.length.split(':')[0]) * 60 +
    //           Number(self.props.activesong.length.split(':')[1])) *
    //         1000

    //       spotifyTimer = self.setSpotifyTimer(0, durationInMilliseconds)
    //       self.setState({
    //         spotifyTimer: spotifyTimer,
    //       })
    //     }

    //     if (
    //       state.track_window.previous_tracks.find(x => x.id === state.track_window.current_track.id)
    //       && (self.currentSpotifyPosition >= state.duration)
    //       ) {
    //       console.log('Track ended');
    //       self.spotifyTimerToggle(false)
    //       self.spotifyTrackEnded()
    //     }
    //   }
    // })
    newSpotify.on('player_state_changed', async (state) => {
      self.setState({
        spotifyState: state,
      });
      if (self.state.spotifyLoading) return;

      self.spotifyContentDuration = state.duration || 0;

      // trigger next track
      if (self.currentSpotifyPosition >= state.duration && state.position === 0) {
        this.currentSpotifyPosition = 0;

        const index = self.props.audiolist.findIndex((item) => item.id === self.props.activesong.id);

        if (this.props.audiolist[index + 2] !== undefined) {
          const announcementTriggered = await this.triggerSpotifyDj(index);

          if (announcementTriggered) {
            this.spotifyTrackEnded();
          }
        } else {
          this.spotifyTrackEnded();
        }
      }
    });

    // Ready
    var playeready = new Promise((res, rej) => {
      newSpotify.on('ready', (data) => {
        let { device_id } = data;
        newSpotify.deviceid = device_id;
        this.setState({ deviceId: device_id });
        res(newSpotify);
      });
    });

    newSpotify.connect();
    return playeready;
  }

  async triggerSpotifyDj(index) {
    if (this.state.djplayed) {
      return true;
    }

    const nextAudio = this.props.audiolist[index + 2];
    if (!nextAudio) {
      return false; // No upcoming audio
    }

    try {
      if (nextAudio.type === 'normal') {
        await this.playUpcomingDj('Top news Stories Coming Up Next. You are listening to Audio One radio.');
      } else if ((nextAudio.type === 'shorts' || nextAudio.type === 'normal') && this.props.sessionMode) {
        await this.playUpcomingDj(`You're listening to AudioOne radio. Up next, a short news break.`);
      } else if (nextAudio.type === 'podcast') {
        await this.playUpcomingDj(nextAudio.title, true);
      }
      return true; // Resolving the promise here after successful execution
    } catch (error) {
      console.error('Error in triggerSpotifyDj:', error);
      return false; // Rejecting the promise in case of an error
    }
  }

  async playUpcomingDj(message, podcast = false) {
    this.setState({
      djplayed: true,
    });

    try {
      await this.state.activespotplayer.setVolume(0.1);
      await this.upcomingAudioV2(message, podcast);
      fadeVolIn(this.state.activespotplayer, 0.1);
      return true; // Resolving the promise after successful execution
    } catch (error) {
      console.error('Error in playUpcomingDj:', error);
      throw error; // Propagating the error for handling in the caller function
    }
  }

  seekPlayer(ev, value) {
    if (this.props.activesong.type == 'spotify') {
      const position = this.spotifyContentDuration * value;
      this.seekTrack(Math.round(position));
    } else if (this.props.activesong.type == 'youtube') {
      window.parent.postMessage(
        {
          type: 'setVideoPosition',
          value,
        },
        '*'
      );
    } else {
      if (this.player) {
        var position = this.player.duration * value;
        this.seekTrack(position);
      }
    }
  }

  // async seekSpotify(value) {
  //   const self = this
  //   // await this.state.activespotplayer.getCurrentState().then(async state => {
  //   //   if (state) {
  //   if (self.props.playing && this.state.activespotplayer) {
  //     var durationInMilliseconds =
  //       (Number(self.props.activesong.length.split(':')[0]) * 60 +
  //         Number(self.props.activesong.length.split(':')[1])) *
  //       1000
  //     await self.state.activespotplayer.seek(value * durationInMilliseconds)
  //     const newstate = await this.state.activespotplayer.getCurrentState()
  //     self.spotifyTimerToggle(false)

  //     self.setState({
  //       wavePosition: value,
  //     })

  //     if (newstate) {
  //       const spotTimer = self.setSpotifyTimer(
  //         newstate.position,
  //         newstate.duration
  //       )
  //       self.setState({
  //         spotifyTimer: spotTimer,
  //       })
  //     }
  //   } else {
  //     await self.state.activespotplayer.seek(
  //       value * self.props.activeSong.length
  //     )
  //     self.spotifyTimerToggle(false)

  //     self.setState({
  //       waveposition: value,
  //     })
  //   }
  // }

  setYoutubeTimer(remTime, duration) {
    var self = this;
    var currentTime = remTime;

    var totalTime = duration;
    let trackposition = currentTime / totalTime;
    var remainingTime = totalTime - currentTime;

    var tminute = Math.floor(totalTime / 60); // get minute(integer) from time
    var ttmp = Math.round(totalTime - tminute * 60); // get second(integer) from time
    var tsecond = (ttmp < 10 ? '0' : '') + ttmp; // make two-figured integer if less than 10

    var rminute = Math.floor(remainingTime / 60); // get minute(integer) from time
    var rtmp = Math.round(remainingTime - rminute * 60); // get second(integer) from time
    var rsecond = (rtmp < 10 ? '0' : '') + rtmp; // make two-figured integer if less than 10
    totalTime = String(tminute + ':' + tsecond) === 'NaN:NaN' ? '' : String(tminute + ':' + tsecond);
    remainingTime = String(rminute + ':' + rsecond) === 'NaN:NaN' ? '' : String(rminute + ':' + rsecond);

    self.setState({
      totaltime: totalTime,
      remtime: remainingTime,
      waveposition: trackposition,
    });
  }

  setSpotifyTimer(remTime, duration) {
    var self = this;
    var remainTime = remTime;
    var random = Math.floor(Math.random() * (+3 - +1)) + +1;
    random = random % 2;
    var timeInterval = setInterval(async () => {
      if (self.state.activespotplayer) {
        const state = await self.state.activespotplayer.getCurrentState();
        if (state) remainTime = state.position;
      }
      var currentTime = remainTime;
      currentTime = currentTime / 1000;
      if (random == 0) {
        let content = "You're listening to Audio One radio.  Music, news and podcasts in one playlist.  It's just better together.";
        let playType = false;

        if (self.props.sessionMode) {
          content = self.props.sessionMode;
          playType = 'session';
        }

        self.playIntro.call(self, content, true, playType);
      } else {
        if (currentTime >= 10) {
          let content = "You're listening to Audio One radio.  Music, news and podcasts in one playlist.  It's just better together.";
          let playType = false;

          if (self.props.sessionMode) {
            content = self.props.sessionMode;
            playType = 'session';
          }

          self.playIntro.call(self, content, true, playType);
        }
      }

      var totalTime = duration / 1000;
      let trackposition = currentTime / totalTime;
      var remainingTime = totalTime - currentTime;

      var tminute = Math.floor(totalTime / 60); // get minute(integer) from time
      var ttmp = Math.round(totalTime - tminute * 60); // get second(integer) from time
      var tsecond = (ttmp < 10 ? '0' : '') + ttmp; // make two-figured integer if less than 10

      var rminute = Math.floor(remainingTime / 60); // get minute(integer) from time
      var rtmp = Math.round(remainingTime - rminute * 60); // get second(integer) from time
      var rsecond = (rtmp < 10 ? '0' : '') + rtmp; // make two-figured integer if less than 10
      totalTime = String(tminute + ':' + tsecond) === 'NaN:NaN' ? '' : String(tminute + ':' + tsecond);
      remainingTime = String(rminute + ':' + rsecond) === 'NaN:NaN' ? '' : String(rminute + ':' + rsecond);

      //  To detect end of track based on duration
      // if (rminute == 0 && rsecond <= 1) {
      //   self.state.activespotplayer.seek(0);
      //   self.state.activespotplayer.pause();
      //   self.spotifyTimerToggle(false);
      //   self.spotifyTrackEnded();
      // }

      remainTime += 1000;
      self.setState({
        totaltime: totalTime,
        remtime: remainingTime,
        waveposition: trackposition,
      });
    }, 1000);

    return timeInterval;
  }

  async spotifyTrackEnded() {
    const self = this;
    self.setState({
      djplayed: false,
    });

    var tempitem = self.props.audiolist.findIndex((item) => item.id === self.props.activesong.id);
    var index = tempitem + 1;
    const prevTrack = this.props.activesong;
    this.props.setCompleted(prevTrack);

    // To check if its time to reinitialize spotify sdk with new token
    // if (self.state.spotifyTokenRenewal) {
    //   self.setState({
    //     renewalProgress: true,
    //   });
    //   await self.createSpotify(self.state.spotifyToken);
    //   self.setState({
    //     spotifyTokenRenewal: false,
    //     renewalProgress: false,
    //   });
    // }
    if (typeof self.props.audiolist[index] === 'undefined') {
      self.props.setActiveSong(false);
      self.props.toggleSong(false);
      self.setState({
        remTime: '',
        totaltime: '',
      });
    } else {
      var newaud = self.props.audiolist[index];
      self.props.setActiveSong(newaud);
      self.loadAudio();

      console.log(index, 'index');

      if (newaud.type === 'spotify') {
        await self.playSpotifyTrack(newaud.link);
      } else if (newaud.type !== 'youtube') {
        setTimeout(() => {
          let load = self.createAudio(self.props.volume);
          load.src = newaud.link;
          load.load();
          load.play();
        }, 1000);
      }
    }
  }

  async spotifyTimerToggle(playerPlayState) {
    const self = this;
    if (playerPlayState) {
      if (this.state.remtime && this.state.totaltime) {
        await this.state.activespotplayer.getCurrentState().then((state) => {
          if (state) {
            const spotTimer = self.setSpotifyTimer(state.position, state.duration);

            self.setState({
              spotifyTimer: spotTimer,
              remTime: false,
              totalTime: false,
            });
          }
        });
      }
    } else {
      if (this.state.spotifyTimer) {
        clearInterval(this.state.spotifyTimer);
        this.setState({
          spotifyTimer: false,
          remTime: false,
          totalTime: false,
        });
      }
    }
  }

  // To trigger dj mode
  upcomingAudio(content, podcast = false) {
    var str = '';
    if (podcast) {
      str = content;
      if (podcast == 'continue') str += '/continue';
      else str += '/podcast';
    } else {
      str = content.replace(/[&\/\\#+()$~%'":*?<>{}]/g, '');
    }
    document.addEventListener('click', clickDisable, true);
    this.setState({ announcerPlaying: true });
    var arr = new Audio(`https://djdev.audioone.cloud/generatevoice/${str}`);
    arr.load();
    arr.play();

    var self = this;
    var tempromise = new Promise((resolve, reject) => {
      arr.addEventListener('ended', function () {
        document.removeEventListener('click', clickDisable, true);
        self.setState({ announcerPlaying: false });
        resolve(true);
      });
    });

    return tempromise;
  }

  upcomingAudioV2(content, podcast = false) {
    return new Promise((resolve, reject) => {
      const str = podcast ? content : content.replace(/[&\/\\#+()$~%'":*?<>{}]/g, '');

      document.addEventListener('click', clickDisable, true);
      this.setState({ announcerPlaying: true });

      const arr = new Audio(`https://djdev.audioone.cloud/generatevoice/${str}`);
      arr.load();
      arr.play();

      arr.addEventListener('ended', () => {
        document.removeEventListener('click', clickDisable, true);
        this.setState({ announcerPlaying: false });
        resolve(true);
      });

      arr.addEventListener('error', (error) => {
        console.error('Error in upcomingAudio:', error);
        reject(error);
      });
    });
  }

  preloadInitialAudio() {
    const intro = "You're listening to Audio One radio.  Music, news and podcasts in one playlist.  It's just better together.";
    let audio = new Audio(`https://djdev.audioone.cloud/generatevoice/${intro}`);
    this.preloadedAudio = audio;
  }

  async stopSilenceAudio() {
    this.state.stopTheSilenceAudio = true;

    this.stopAnnouncer();
  }

  async playIntro(introMessage = '', spotify = false, playMode = false) {
    const self = this;

    if ((this.state.introplayed && this.intermediateMusicPlaying) || this.state.announcerPlaying) {
      return;
    }

    // if (spotify) {
    //   await this.state.activespotplayer.setVolume(0.1)
    // }

    if (playMode) {
      introMessage += `/undefined/undefined/${playMode}`;
    }

    document.addEventListener('click', clickDisable, true);

    this.introAudio = null;
    if (!this.state.introplayed) {
      this.setState({ announcerPlaying: true });
      if (this.state.disableAds || this.state.advertisementCookieSet) {
        if (this.isStationTriggered && this.stationIntroMessage) {
          this.introAudio =
            this.preloadedStationAudio || new Audio(`https://djdev.audioone.cloud/generatevoice/${this.stationIntroMessage}`);
        } else {
          this.introAudio = !!(!introMessage && this.preloadedAudio)
            ? this.preloadedAudio
            : new Audio(`https://djdev.audioone.cloud/generatevoice/${introMessage}`);
        }
      } else {
        // audio = new Audio('/audioOne_first_visit.mp3')
        if (this.isStationTriggered && this.stationIntroMessage) {
          this.introAudio =
            this.preloadedStationAudio || new Audio(`https://djdev.audioone.cloud/generatevoice/${this.stationIntroMessage}`);
        } else {
          this.introAudio = this.preloadedAudio || new Audio(`https://djdev.audioone.cloud/generatevoice/${introMessage}`);
        }
      }

      this.isStationTriggered = false;

      this.introAudio.play();

      this.setState({
        introplayed: true,
      });

      const tempromise = new Promise((resolve, reject) => {
        this.introAudio.addEventListener('ended', function () {
          self.setState({
            announcerPlaying: false,
            introPlayingEnded: true,
          });
          document.removeEventListener('click', clickDisable, true);

          if (self.state.activespotplayer && self.props.activesong.type == 'spotify') {
            if (self.spotifyPlaySongLink) {
              self.playSpotifyTrack(self.spotifyPlaySongLink);
            }
          } else {
            const timeoutId = setTimeout(() => {
              if (!self.metadataLoaded && !self.state?.stopTheSilenceAudio) {
                self.playIntermediateMusic(false);
              } else if (self.metadataLoaded) {
                self.player?.play();
              }
              clearTimeout(timeoutId);
            }, 500);
          }

          resolve(true);
        });
      });

      return tempromise;
    } else {
      // commenting this for now, in case we need to play silence audio in future between elements. Do not delete
      if (!this.isTimeoutAdded && !self.state.activespotplayer && self.props.activesong.type !== 'spotify') {
        this.isTimeoutAdded = true;
        const timeoutId = setTimeout(() => {
          if (!self.metadataLoaded) {
            this.playIntermediateMusic();
          }
          clearTimeout(timeoutId);
          this.isTimeoutAdded = false;
        }, 3000);
      }
    }
  }

  playIntermediateMusic(shouldPause = true) {
    if (this.intermediateMusicContext || this.state.activespotplayer) {
      return;
    }

    const self = this;
    self.intermediateMusicPlaying = true;
    this.intermediateMusicContext = new Audio('/audioOne_silence.mp3');

    if (shouldPause && self.player) {
      self.player?.pause();
    }

    this.intermediateMusicContext.play();
    this.intermediateMusicContext.addEventListener('ended', function () {
      self.intermediateMusicPlaying = false;
    });
  }

  // async playIntro(intro, spotify = false, playMode = false) {
  //   if (this.state.introplayed || this.state.announcerPlaying) return
  //   if (spotify == true) await this.state.activespotplayer.setVolume(0.1)

  //   if (playMode) intro += `/undefined/undefined/${playMode}`

  //   var self = this

  //   document.addEventListener('click', clickDisable, true)
  //   this.setState({ announcerPlaying: true, introAnnouncerPlaying: true })
  //   let arr
  //   if (this.state.disableAds || this.state.advertisementCookieSet) {
  //     arr = new Audio(`https://djdev.audioone.cloud/generatevoice/${intro}`)
  //   } else {
  //     arr = new Audio(`https://djdev.audioone.cloud/generatevoice/${intro}`)
  //     // arr = new Audio('/audioOne_first_visit.mp3')
  //   }

  //   arr.load()
  //   arr.play()
  //   this.setState({
  //     introplayed: true,
  //   })

  //   var tempromise = new Promise((resolve, reject) => {
  //     arr.addEventListener('ended', function () {
  //       self.setState({ announcerPlaying: false, introAnnouncerPlaying: false })
  //       document.removeEventListener('click', clickDisable, true)
  //       if (spotify == true) {
  //         fadeVolIn(self.state.activespotplayer, 0.1)
  //       }

  //       self.player.play()
  //       resolve(true)
  //     })
  //   })

  //   return tempromise
  // }

  getAdvertisementData(item) {
    const data = {
      title: item.title,
      desc: 'desc',
      // link: item.link || '/audioOne_first_visit.mp3',
      link: item.link || '',
      image: '',
      id: item.id,
      length: 0,
      type: 'advertisement',
      isPost: '',
      subtype: item.subtype,
      advertisementType: item.advertisementType || '',
    };
    return data;
  }

  async playCommentary(link) {
    var self = this;

    document.addEventListener('click', clickDisable, true);
    this.setState({ announcerPlaying: true });
    var arr = new Audio(link);
    arr.load();
    arr.play();

    var self = this;

    var tempromise = new Promise((resolve, reject) => {
      arr.addEventListener('ended', function () {
        self.setState({ announcerPlaying: false });
        document.removeEventListener('click', clickDisable, true);
        setTimeout(() => {
          resolve(true);
        }, 3000);
      });
    });

    return tempromise;
  }

  // savePodcastDuration() {
  //   window.parent.postMessage(
  //     {
  //       type: "setLastPlayed",
  //       duration: this.player?.currentTime,
  //       track_id: this.props.activesong.id,
  //     },
  //     "*"
  //   );
  // }

  saveMusicHistory(item, saveDuration = true) {
    item['updated'] = +new Date();
    if (saveDuration) item['duration'] = this.state.spotifyState.position;
    else delete item['duration'];

    const allitems = [...this.props.audioHistory, item];
    this.props.setAudioHistory(allitems);
    window.parent.postMessage(
      {
        type: 'setAudioHistory',
        queue: allitems,
      },
      '*'
    );
  }

  saveAudioHistory(item, saveDuration = true) {
    // if (item.id.includes("preview")) return;

    item['updated'] = +new Date();
    if (saveDuration) item['duration'] = this.player.currentTime;
    else delete item['duration'];

    const allitems = [...this.props.audioHistory, item];
    this.props.setAudioHistory(allitems);

    window.parent.postMessage(
      {
        type: 'setAudioHistory',
        queue: allitems,
      },
      '*'
    );
  }

  render() {
    return (
      <div>
        <div
          id='sonaar-player'
          className={`${this.props.visible ? 'enable' : ''}
            ${this.state.theme}
          list-type-album ${this.props.playlist ? 'show-list' : ''} ${this.props.search ? 'show-list-search' : ''}`}
        >
          <SearchPage />
          {this.props.playlist ? <div className='close btn_playlist' onClick={() => this.toggleList(true)} /> : ''}

          {this.props.search ? <div className='close btn_playlist' onClick={() => this.toggleList(false)} /> : ''}
          <ToggleButton visible={this.props.visible} toggleVisibity={this.toggleVisibility.bind(this)} />
          <div className='player no-list'>
            <div className='player-row'>
              <div className='playerNowPlaying'>
                <AlbumArt data={this.props.activesong} />
              </div>
              <div className='control'>
                <PreviousButton
                  aud={this.player}
                  activespot={this.state.activespotplayer}
                  saveAudioHistory={this.saveAudioHistory.bind(this)}
                  spotifyTimerToggle={this.spotifyTimerToggle.bind(this)}
                  createAudio={this.createAudio.bind(this)}
                />
                <PlayButton
                  spotifyTimerToggle={this.spotifyTimerToggle.bind(this)}
                  activeaud={this.player}
                  secondaryPlayer={this.secondaryPlayer}
                  saveAudioHistory={this.saveAudioHistory.bind(this)}
                  activespot={this.state.activespotplayer}
                />

                <NextButton
                  saveAudioHistory={this.saveAudioHistory.bind(this)}
                  spotifyTimerToggle={this.spotifyTimerToggle.bind(this)}
                  aud={this.player}
                  activespot={this.state.activespotplayer}
                  createAudio={this.createAudio.bind(this)}
                  markArticleAsRead={this.markArticleAsRead.bind(this)}
                />
              </div>
              <div className='wavesurfer'>
                <div className='timing'>
                  <div className='time timing_currentTime'> {this.state.remtime} </div>
                  <div className='time timing_totalTime'> {this.state.totaltime} </div>
                </div>
                <div className={`wave-custom `}>
                  <Slider
                    value={this.state.waveposition}
                    onChange={this.seekPlayer.bind(this)}
                    step={0.00000001}
                    min={0}
                    max={1}
                    aria-labelledby='continuous-slider'
                  />
                </div>

                <VolumeControl player={this.player} activespot={this.state.activespotplayer} />
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
