import React, { Component } from "react";

class ToggleButton extends Component {
  state = { visible: this.props.visible };

  componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible
    });
  }

  toggleVisibility() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));

    this.props.toggleVisibity();
  }
  render() {
    return (
      <div
        onClick={() => this.toggleVisibility()}
        className={`close btn-player ${this.state.visible ? "enable" : ""}`}
      >
        <svg
          className="audioBar"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 17 17"
        >
          <rect x="0" width="2" height="16" transform="translate(0)">
            <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="2;16;2"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="5" width="2" height="16" transform="translate(0)">
            <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="2;16;2"
              repeatCount="indefinite"
              begin="0.3s"
            />
          </rect>
          <rect x="10" width="2" height="16" transform="translate(0)">
            <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="2;16;2"
              repeatCount="indefinite"
              begin="0.5s"
            />
          </rect>
          <rect x="15" width="2" height="16" transform="translate(0)">
            <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="2;16;2"
              repeatCount="indefinite"
              begin="0.3s"
            />
          </rect>
        </svg>
      </div>
    );
  }
}

export default ToggleButton;
