import React, { Component } from "react";
import ACTIONS from "../modules/actions/player";
import { connect } from "react-redux";

var mapStateToProps = (state) => {
  return {
    playing: state.player.playing,
    audiolist: state.player.audio,
    activesong: {
      title: state.player.activeSong.title,
      desc: state.player.activeSong.desc,
      image: state.player.activeSong.image,
      link: state.player.activeSong.link,
      id: state.player.activeSong.id,
      type: state.player.activeSong.type,
    },
  };
};

var mapDispatchToProps = (dispatch) => ({
  setActiveSong: (item) => dispatch(ACTIONS.setActiveSong(item)),
  toggleSong: (cond) => dispatch(ACTIONS.toggleSong(cond)),
  setDuration: (item) => dispatch(ACTIONS.setDuration(item)),
});

class Player extends Component {
  playPause() {
    let foundItem = this.props.audiolist.find(
      (item) => item.id == this.props.activesong.id
    );
    this.props.saveAudioHistory(foundItem);

    if (this.props.activesong.type === "spotify") {
      this.props.activespot.togglePlay().then(() => {});
      this.props.spotifyTimerToggle(!this.props.playing);
    } else if (this.props.activesong.type !== "youtube") {
      if (this.props.playing) {
        this.props.activeaud.pause();
        if (this.props.secondaryPlayer) this.props.secondaryPlayer.pause();
      } else {
        this.props.activeaud.play();
        if (this.props.secondaryPlayer) this.props.secondaryPlayer.play();
      }
    } else {
      window.parent.postMessage(
        {
          type: "toggleVideo",
          item: this.props.activesong,
        },
        "*"
      );
    }
    this.props.toggleSong(!this.props.playing);
  }

  render() {
    return (
      <div
        className={`play-btn play ${this.props.playing ? "audio-playing" : ""}`}
        onClick={() => this.playPause()}
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 17.5 21.2"
        >
          <path d="M0,0l17.5,10.9L0,21.2V0z" />
          <rect width="6" height="21.2" />
          <rect x="11.5" width="6" height="21.2" />
        </svg>{" "}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Player);
