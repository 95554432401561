import React from "react";

import "./App.css";
import "./player.css";
import "./custom.css";
import AppContainer from "./components/AppContainer";
import configureStore from "./modules/store";
import { Provider as ReduxProvider } from "react-redux";
var reduxStore = configureStore(window.REDUX_INITIAL_DATA);

function App() {
  return (
    <ReduxProvider store={reduxStore}>
      <div className="App">
        <AppContainer />
      </div>
    </ReduxProvider>
  );
}

export default App;
