import React, { Component } from "react";
import ACTIONS from "../modules/actions/player";
import { connect } from "react-redux";
import axios from "axios";
import Sortable from "react-sortablejs";
import { CLIENT_RENEG_LIMIT } from "tls";

var mapStateToProps = state => {
  return {
    visibility: state.player.playlist,
    audiolist: state.player.audio,
    activesong: {
      title: state.player.activeSong.title,
      id: state.player.activeSong.id,
      type: state.player.activeSong.type
    },
    playlist: state.player.audio,
    playing: state.player.playing,
    volume: state.player.volume
  };
};

var mapDispatchToProps = dispatch => ({
  setActiveSong: item => dispatch(ACTIONS.setActiveSong(item)),
  addPlaylist: items => dispatch(ACTIONS.addPlaylist(items)),
  toggleSong: cond => dispatch(ACTIONS.toggleSong(cond)),
  removePlaylist: items => dispatch(ACTIONS.removePlaylist(items)),
  updateListOrder: item => dispatch(ACTIONS.updateListOrder(item))
});

class PlayList extends Component {
  state = {
    results: [],
    query: "",
    loading: false
  };

  playAudio(index) {
    const article = this.state.results[index];
    axios
      .post("https://audione-search.herokuapp.com/getArticle", {
        article: article
      })
      .then(res => {
        console.log(res.data);
        if (res.data.error) {
          const tempItem = this.state.results[index];
          tempItem.playable = false;
          const tempArray = this.state.results;
          tempArray.splice(index, 1, tempItem);
          this.setState({
            results: tempArray
          });
          return;
        }

        var obj = {};
        obj.link = res.data.link;
        obj.sid = res.data.sid;
        obj.image = res.data.image;
        obj.description = res.data.description;
        obj.type = "newaudio";
        window.postMessage(obj, window.location.origin);
      });
  }

  searchSubmit(e) {
    e.preventDefault();
    this.setState({
      results: [],
      loading: true
    });
    axios
      .post("https://audione-search.herokuapp.com/form", {
        query: this.state.query
      })
      .then(res => {
        console.log(res.data);
        const results = res.data.map(item => {
          item.playable = true;
          return item;
        });

        this.setState({
          results: results,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <div name="sonaar-player-slidefade">
        <div className={`playlist search-page`}>
          {this.state.loading ? (
            <div className="pace pace-active">
              <div
                className="pace-progress"
                data-progress="50"
                data-progress-text="50%"
                style={{ transform: "translate3d(50%, 0px, 0px)" }}
              >
                <div className="pace-progress-inner"></div>
              </div>
              <div className="pace-activity"></div>
            </div>
          ) : (
            ""
          )}
          <div className="scroll">
            <div className="container">
              <form
                method="POST"
                id="searchForm"
                onSubmit={this.searchSubmit.bind(this)}
              >
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="query"
                    className="form-control"
                    placeholder="Type to search"
                    value={this.state.query}
                    onChange={e => this.setState({ query: e.target.value })}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-primary" type="submit">
                      <span className="fas fa-search"></span>
                    </button>
                  </div>
                </div>
              </form>
              <div className="boxed">
                <div className="trackscroll">
                  <div className="tracklist">
                    {this.state.results.map((item, index) => {
                      return (
                        <li key={index} data-id={index}>
                          <span className="track-status">{index + 1}</span>
                          <span className="track-image">
                            {" "}
                            <img src={item.image} alt="" />
                          </span>
                          <span
                            className="track-title"
                            onClick={this.playAudio.bind(this, index)}
                          >
                            <span
                              className={`content ${
                                item.playable ? "" : "not-playable"
                              } `}
                            >
                              <span> {item.title} </span>
                              <p>{item.description}</p>
                            </span>
                          </span>
                          <span
                            // className="track-store"
                            className="sonaar-callToAction-play"
                            data-id={index}
                          >
                            {item.playable ? (
                              <div
                                className={`play`}
                                onClick={this.playAudio.bind(this, index)}
                              >
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 17.5 21.2"
                                >
                                  <path d="M0,0l17.5,10.9L0,21.2V0z" />
                                  <rect width="6" height="21.2" />
                                  <rect x="11.5" width="6" height="21.2" />
                                </svg>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </li>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayList);
