import React, { Component } from "react";
import ACTIONS from "../modules/actions/player";
import { connect } from "react-redux";

var mapStateToProps = (state) => {
  return {
    activesong: {
      title: state.player.activeSong.title,
      desc: state.player.activeSong.desc,
      image: state.player.activeSong.image,
      link: state.player.activeSong.link,
      id: state.player.activeSong.id,
      type: state.player.activeSong.type,
    },
    audiolist: state.player.audio,
    volume: state.player.volume,
  };
};
var mapDispatchToProps = (dispatch) => ({
  setActiveSong: (item) => dispatch(ACTIONS.setActiveSong(item)),
  toggleSong: (cond) => dispatch(ACTIONS.toggleSong(cond)),
  setDuration: (item) => dispatch(ACTIONS.setDuration(item)),
  setCompleted: (item) => dispatch(ACTIONS.setCompleted(item)),
});

class NextButton extends Component {
  async componentDidMount() {
    const self = this;
    // window.addEventListener("message", async function (event) {
    //   var item = event.data;
    //   if (item.type == "playNext") self.nextAudio.call(self);
    // });
  }

  async nextAudio() {
    var tempitem = this.props.audiolist.findIndex(
      (item) => item.id === this.props.activesong.id
    );

    var index = tempitem + 1;

    if (typeof this.props.audiolist[index] === "undefined") {
      console.log("undefined track");
    } else {
      let foundItem = this.props.audiolist.find(
        (item) => item.id == this.props.activesong.id
      );
      this.props.saveAudioHistory(foundItem);

      if (this.props.activesong.type == "podcast") {
        this.props.setDuration({
          trackId: this.props.activesong.id,
          duration: this.props.aud.currentTime,
        });
      }

      const prevTrack = this.props.activesong;
      this.props.setCompleted(prevTrack);

      if (this.props.activesong.type == "normal") {
        this.props.markArticleAsRead(prevTrack);
      }

      if (this.props.activesong.type === "spotify") {
        if (this.props.activespot) {
          await this.props.activespot.seek(0);
          await this.props.activespot.pause();
        }
      } else {
        this.props.aud.pause();
        this.props.aud.currentTime = 0;
      }

      var newaud = this.props.audiolist[index];
      this.props.setActiveSong(newaud);
      this.props.toggleSong(true);

      if (newaud.type === "spotify") {
        this.props.spotifyTimerToggle(false);
        this.props.activespot.loadTrack(newaud.link);
      } else {
        let load = this.props.createAudio(this.props.volume);
        load.src = newaud.link;
        load.load();
        load.play();
      }
    }
  }

  render() {
    return (
      <div className="next" onClick={this.nextAudio.bind(this)}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 10.2 11.7"
        >
          <polygon points="0,11.7 8.8,6.4 8.8,11.7 10.2,11.7 10.2,0 8.8,0 8.8,5.6 0,0" />
        </svg>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NextButton);
